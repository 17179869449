import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Terms from "../public/Legal";
import Privacy from "../public/Legal";
import Careers from "../public/Careers";

function BottomNav() {
  return (
    <Router>
      <div className="bottom-nav row">
        <div className="d-flex flex-column mx-auto">
          <div className="col-stretch cen-bot text-center">
            <li className="list-inline-item">
              <a className="text-light no-decoration" href="/terms">
                Terms
              </a>
            </li>
            <li className="list-inline-item text-light">⋅</li>
            <li className="list-inline-item">
              <a className="text-light no-decoration" href="/privacy">
                Privacy
              </a>
            </li>
            <li className="list-inline-item text-light">⋅</li>
            <li className="list-inline-item">
              <a className="text-light no-decoration" href="/careers">
                Careers
              </a>
            </li>
          </div>
          <div className="col-stretch cen-mid text-center">
            <p className="text-light small">
              © Bookkeeping Stop 2019. All Rights Reserved.
            </p>
          </div>
        </div>
        <Route path="/src/components/pages/Terms.js" component={Terms} />
        <Route path="/src/components/pages/Privacy.js" component={Privacy} />
        <Route path="/src/components/pages/Careers.js" component={Careers} />
      </div>
    </Router>
  );
}

/* <div className="col-stretch cen-mid text-center">
            <p className="text-light small">
              Icons made by{" "}
              <a
                className="text-light no-decoration"
                href="https://www.flaticon.com/authors/flat-icons"
                title="Flat Icons"
              >
                Flat Icons
              </a>{" "}
              from{" "}
              <a
                className="text-light no-decoration"
                href="https://www.flaticon.com/"
                title="Flaticon"
              >
                www.flaticon.com
              </a>{" "}
              is licensed by{" "}
              <a
                className="text-light no-decoration"
                href="http://creativecommons.org/licenses/by/3.0/"
                title="Creative Commons BY 3.0"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                CC 3.0 BY
              </a>
            </p>
          </div>*/

export default BottomNav;
