import React, { Component } from "react";
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
import { toast } from "react-toastify";
toast.configure();

class Checkout extends Component {
  state = {
    product: {
      name: "Tesla Roadster",
      price: 1.0,
      description: "Cool car"
    }
  };

  async handleToken(token, addresses) {
    const product = this.product;
    const response = await axios.post(
      "https://cors-anywhere.herokuapp.com/https://us-central1-bkstop-a40d9.cloudfunctions.net/stripePayment",
      { token, product }
    );
    const { status } = response.data;
    console.log("Response:", response.data);
    if (status === "success") {
      toast("Success! Check email for details", { type: "success" });
    } else {
      toast("Something went wrong", { type: "error" });
    }
  }
  render() {
    const { product } = this.state;
    return (
      <div className="container">
        <div className="product">
          <h1>{product.name}</h1>
          <h3>On Sale · ${product.price}</h3>
        </div>
        <StripeCheckout
          stripeKey="pk_live_OB4Xv6ya4ZpBG8SwGy8X78Ib"
          token={this.handleToken}
          amount={product.price * 100}
          name="Tesla Roadster"
          billingAddress
          shippingAddress
        />
      </div>
    );
  }
}

export default Checkout;
