import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SignIn from "../auth/SignIn";
import SignUp from "../auth/SignUp";
import SignUpOne from "../auth/SignUpOne";
import SignUpTwo from "../auth/SignUpTwo";
import SignUpThree from "../auth/SignUpThree";
import Home from "../public/Home";
import About from "../public/About";
import Services from "../public/Services";
import Pricing from "../public/Pricing";
import Clients from "../public/Clients";
import Contact from "../public/Contact";
import Referral from "../public/Referral";
import PDFParser from "../public/PDFParser";
import Portal from "../public/Portal";
import Legal from "../public/Legal";
import Terms from "../public/Terms";
import Hairhouse from "../public/Hairhouse";
import Privacy from "../public/Privacy";
import Careers from "../public/Careers";
import { connect } from "react-redux";
import ClientHome from "../client/ClientHome";
import Account from "../client/Account";
import Profile from "../client/Profile";
import DeleteAccount from "../client/DeleteAccount";
import View from "../admin/View";
import TicketList from "../client/TicketList";
import TicketDetails from "../client/TicketDetails";
import TicketUpdate from "../client/TicketUpdate";
import Upload from "../client/Upload";
import Parser from "../public/Scraper";
import ContactForm from "../public/ContactForm";
import Reports from "../client/Reports";
import Activity from "../client/Activity";
import Analytics from "../client/Analytics";
import Checkout from "../stripe/Checkout";
import UpdateCard from "../stripe/UpdateCard";
import Graph from "../client/Graph";
//import ClientSideBar from "./ClientSideBar";

const Main = props => {
  const { auth } = props;

  const layout = auth.uid ? (
    <Router>
      <Switch>
        <Route path="/signin" component={ClientHome} />
        <Route path="/signup" component={ClientHome} />
        <Route path="/clientHome" component={ClientHome} />
        <Route path="/account" component={Account} />
        <Route path="/profile" component={Profile} />
        <Route path="/delete" component={DeleteAccount} />
        <Route path="/view" component={View} />
        <Route path="/support" component={TicketList} />
        <Route path="/ticket/:id" component={TicketDetails} />
        <Route path="/update/:id" component={TicketUpdate} />
        <Route path="/activity" component={Activity} />
        <Route path="/reports" component={Reports} />
        <Route path="/analytics" component={Analytics} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/updateCard" component={UpdateCard} />
        <Route path="/graph" component={Graph} />
      </Switch>
    </Router>
  ) : (
    <Router>
      <Switch>
        <Route path="/parser" component={Parser} />
        <Route exact path="/" component={Home} />
        <Route path="/home" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/services" component={Services} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/clients" component={Clients} />
        <Route path="/upload" component={Upload} />
        <Route path="/contact" component={Contact} />
        <Route path="/contactForm" component={ContactForm} />
        <Route path="/referral" component={Referral} />
        <Route path="/pdfParser" component={PDFParser} />
        <Route path="/portal" component={Portal} />
        <Route path="/legal" component={Legal} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/hairhouse" component={Hairhouse} />
        <Route path="/careers" component={Careers} />
        <Route path="/signin" component={SignIn} />
        <Route path="/signup" component={SignUp} />
        <Route path="/signupone" component={SignUpOne} />
        <Route path="/signuptwo" component={SignUpTwo} />
        <Route path="/signupthree" component={SignUpThree} />
        <Route path="/view" component={View} />
      </Switch>
    </Router>
  );
  return <div>{layout}</div>;
};

/* <div className="">
      <div className="row">
        <div className="col-1 side-nav">
          <ClientSideBar />
        </div>
        <div className="col-11">
          <Router>
            <Switch>
              <Route path="/signin" component={ClientHome} />
              <Route path="/signup" component={ClientHome} />
              <Route path="/clientHome" component={ClientHome} />
              <Route path="/account" component={Account} />
              <Route path="/profile" component={Profile} />
              <Route path="/view" component={View} />
              <Route path="/changePassword" component={ChangePassword} />
              <Route path="/deleteAccount" component={DeleteAccount} />
              <Route path="/planDetails" component={PlanDetails} />
              <Route path="/paymentDetails" component={PaymentDetails} />
              <Route path="/invoices" component={Invoices} />
              <Route path="/create" component={TicketCreate} />
              <Route path="/support" component={TicketList} />
              <Route path="/ticket/:id" component={TicketDetails} />
              <Route path="/update/:id" component={TicketUpdate} />
              <Route path="/activity" component={Activity} />
              <Route path="/reports" component={Reports} />
              <Route path="/fileUpload" component={FileUpload} />
              <Route path="/contactForm" component={ContactForm} />
              <Route path="/analytics" component={Analytics} />
              <Route path="/checkout" component={Checkout} />
              <Route path="/graph" component={Graph} />
            </Switch>
          </Router>
        </div>
      </div>
    </div>
    */

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(Main);
