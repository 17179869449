import React from "react";
import "./index.css";
//import LogoBar from "./components/layout/LogoBar";
import Navbar from "./components/layout/Navbar";
import Cover from "./components/layout/Cover";
import Main from "./components/layout/Main";
import BottomNav from "./components/layout/BottomNav";
import { BrowserRouter } from "react-router-dom";

const App = props => {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <div className="navbar-height" />
        <Cover />
        <Main />
        <BottomNav />
      </div>
    </BrowserRouter>
  );
};

export default App;
