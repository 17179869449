const initState = {
  stripeError: null,
  accountSuccess: null
};

const stripeReducer = (state = initState, action) => {
  switch (action.type) {
    case "STRIPE_UPDATE":
      console.log("Create account success");
      return {
        ...state,
        accountSuccess: "Create account success",
        stripeError: null
      };
    case "STRIPE_UPDATE_ERROR":
      console.log("Create account error");
      return {
        ...state,
        accountSuccess: null,
        stripeError: action.err.message
      };
    default:
      return state;
  }
};

export default stripeReducer;
