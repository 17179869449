import React, { Component } from "react";

class Terms extends Component {
  render() {
    return (
      <div className="container">
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />

        <div className="row d-flex flex-column">
          <div className="mx-auto">
            <h4 className="">Terms of Use</h4>
          </div>
          <br />
        </div>

        <div className="row">
          <div className="p-4">
            <p className="">
              Welcome to Bookkeeping Stop, an online accounting and personal
              finance management service designed especially for small
              businesses. These Terms of Use are intended to explain our
              obligations as a service provider and Your obligations as a
              customer. Please read them carefully.
            </p>
            <p className="">
              These Terms are binding on any use of the Service and apply to You
              from the time that Bookkeeping Stop provides You with access to
              the Service.
            </p>
            <p className="">
              The Bookkeeping Stop Service will evolve over time based on user
              feedback. These Terms are not intended to answer every question or
              address every issue raised by the use of the Bookkeeping Stop
              Service. Bookkeeping Stop reserves the right to change these terms
              at any time, effective upon the posting of modified terms and
              Bookkeeping Stop will make every effort to communicate these
              changes to You via email or notification via the Website. It is
              likely the terms of use will change over time. It is Your
              obligation to ensure that You have read, understood and agree to
              the most recent terms available on the Website.
            </p>
            <p className="">
              By registering to use the Service you acknowledge that You have
              read and understood these Terms and have the authority to act on
              behalf of any person for whom You are using the Service. You are
              deemed to have agreed to these Terms on behalf of any entity for
              whom you use the Service.
            </p>
            <p className="">
              These Terms were last updated on 11 December 2018 to include new
              provisions relating to Xero’s registration as an ‘account
              information service provider’ in the United Kingdom. The change
              will be effective immediately.
            </p>
            <p className="">
              1.<span className="">Definitions</span>
            </p>
            <p className="">
              <span className="">"Agreement"</span>
            </p>
            <p className="">means these Terms of Use.</p>
            <p className="">
              <span className="">"Access Fee"</span>
            </p>
            <p className="">
              means the monthly fee (excluding any taxes and duties) payable by
              You in accordance with the Fee Schedule.
            </p>
            <p className="">
              <span className="">"Confidential Information"</span>
            </p>
            <p className="">
              includes all information exchanged between the parties to this
              Agreement, whether in writing, electronically or orally, including
              the Service but does not include information which is, or becomes,
              publicly available other than through unauthorised disclosure by
              the other party.
            </p>
            <p className="">
              <span className="">"Data"</span>
            </p>
            <p className="">
              means any data inputted by You or with Your authority into the
              Website.
            </p>
            <p className="">
              <span className="">"Fee Schedule"</span>
            </p>
            <p className="">
              means the information relating to subscriptions and billing set
              out on the Bookkeeping Stop subscriptions and billing pages on the
              <a href="https://bookkeepingstop.com/pricing">Website</a>, or any
              other page(s) on the Website notified by Bookkeeping Stop, which
              may be updated or amended by Bookkeeping Stop from time to time.
            </p>
            <p className="">
              <span className="">"Intellectual Property Right"</span>
            </p>
            <p className="">
              means any patent, trade mark, service mark, copyright, moral
              right, right in a design, know-how and any other intellectual or
              industrial property rights, anywhere in the world whether or not
              registered.
            </p>
            <p className="">
              <span className="">"Service"</span>
            </p>
            <p className="">
              means the online accounting and personal finance management
              services made available (as may be changed or updated from time to
              time by Bookkeeping Stop) via the Website.
            </p>
            <p className="">
              <span className="">"Website"</span>
            </p>
            <p className="">
              means the Internet site at the domain
              <a href="https://www.bookkeepingstop.com">
                www.bookkeepingstop.com
              </a>
              or any other site operated by Bookkeeping Stop.
            </p>
            <p className="">
              <span className="">"Bookkeeping Stop"</span>
            </p>
            <p className="">
              means Sydney Bookeepers Pty Ltd which is incorporated and
              registered witht the on the Australian Securities and Investments
              Comission (ASIC).
            </p>
            <p className="">
              <span className="">"Invited User"</span>
            </p>
            <p className="">
              means any person or entity, other than the Subscriber, that uses
              the Service with the authorisation of the Subscriber from time to
              time.
            </p>
            <p className="">
              <span className="">"Subscriber"</span>
            </p>
            <p className="">
              means the person who registers to use the Service, and, where the
              context permits, includes any entity on whose behalf that person
              registers to use the Service.
            </p>
            <p className="">
              <span className="">"You"</span>
            </p>
            <p className="">
              means the Subscriber, and where the context permits, an Invited
              User. "Your" has a corresponding meaning.
            </p>
            <p className="">
              2.<span className="">Use of Software</span>
            </p>
            <p className="">
              Bookkeeping Stop grants You the right to access and use the
              Service via the Website with the particular user roles available
              to You according to Your subscription type. This right is
              non-exclusive, non-transferable, and limited by and subject to
              this Agreement. You acknowledge and agree that, subject to any
              applicable written agreement between the Subscriber and the
              Invited Users, or any other applicable laws:
            </p>
            <p className="">
              1. the Subscriber determines who is an Invited User and what level
              of user role access to the relevant organization and Service that
              Invited User has
            </p>
            <p className="">
              2. the Subscriber is responsible for all Invited Users’ use of the
              Service
            </p>
            <p className="">
              3. the Subscriber controls each Invited User’s level of access to
              the relevant organization and Service at all times and can revoke
              or change an Invited User’s access, or level of access, at any
              time and for any reason, in which case that person or entity will
              cease to be an Invited User or shall have that different level of
              access, as the case may be
            </p>
            <p className="">
              4. if there is any dispute between a Subscriber and an Invited
              User regarding access to any organization or Service, the
              Subscriber shall decide what access or level of access to the
              relevant Data or Service that Invited User shall have, if any.
            </p>
            <p className="">
              3.
              <span className="">Your Obligations</span>
            </p>
            <p className="">
              1.
              <span className="">Payment obligations:</span>
            </p>
            <p className="">
              An invoice for the Access Fee will be issued each month in
              accordance with the details set out in the Fee Schedule.
              Bookkeeping Stop will continue invoicing You in accordance with
              the Fee Schedule until this Agreement is terminated in accordance
              with clause 8.
            </p>
            <p className="">
              All Bookkeeping Stop invoices will be sent to You, or to a Billing
              Contact whose details are provided by You, by email. Payment of
              all amounts specified in an invoice must be paid in accordance
              with the Fee Schedule. You are responsible for payment of all
              taxes and duties in addition to the Access Fee.
            </p>
            <p className="">
              2.
              <span className="">Preferential pricing or discounts:</span>
            </p>
            <p className="">
              You may from time to time be offered preferential pricing or
              discounts for the Access Fees as a result of the number of
              organizations that You have added to the Service or that have been
              added with Your authority or as a result of Your use of the
              Service ('Organizations'). Eligibility for such preferential
              pricing or discounts is conditional upon Your acceptance of
              responsibility for payment of any Access Fees in relation to all
              of Your Organizations. Without prejudice to any other rights that
              Bookkeeping Stop may have under these Terms or at law, Bookkeeping
              Stop reserves the right to render invoices for the full
              (non-discounted) Access Fees due or suspend or terminate Your use
              of the Service in respect of any or all of Your Organizations in
              the event that any invoices for those Access Fees are not paid in
              full in accordance with the requirements set out in the Fee
              Schedule.
            </p>
            <p className="">
              3.
              <span className="">General obligations:</span>
            </p>
            <p className="">
              You must only use the Service and Website for Your own lawful
              internal business purposes, in accordance with these Terms and any
              notice sent by Bookkeeping Stop or condition posted on the
              Website. You may use the Service and Website on behalf of others
              or in order to provide services to others but if You do so you
              must ensure that You are authorized to do so and that all persons
              for whom or to whom services are provided comply with and accept
              all terms of this Agreement that apply to You.
            </p>
            <p className="">
              4.
              <span className="">
                Automated Bank transaction data delivered into Your Bookkeeping
                Stop account:
              </span>
            </p>
            <p className="">
              Where available, automated bank account transaction data feeds are
              generally provided to You free of charge. However, Bookkeeping
              Stop reserves the right to pass on any charges related to the
              provision of bank feed data on a case-by-case basis at Xero’s sole
              discretion. Bookkeeping Stop would first inform You via email to
              indicate what those charges are likely to be (as such charges may
              vary depending on Your bank and Your volume of bank feeds). You
              have the option to decide to discontinue use of automated bank
              feeds at any time. To exercise this option, you must give
              Bookkeeping Stop sufficient prior notice of which automated bank
              account transaction data feeds you want to discontinue. Upon
              receiving such notice Bookkeeping Stop will arrange for such feeds
              to be terminated in accordance with each bank’s usual practices.
            </p>
            <p className="">
              5.
              <span className="">Access conditions:</span>
            </p>
            <p className="">
              1. You must ensure that all usernames and passwords required to
              access the Service are kept secure and confidential. You must
              immediately notify Bookkeeping Stop of any unauthorized use of
              Your passwords or any other breach of security and Bookkeeping
              Stop will reset Your password and You must take all other actions
              that Bookkeeping Stop reasonably deems necessary to maintain or
              enhance the security of Bookkeeping Stop's computing systems and
              networks and Your access to the Services.
            </p>
            <p className="">
              2. As a condition of these Terms, when accessing and using the
              Services, You must:
            </p>
            <p className="">
              i. not attempt to undermine the security or integrity of
              Bookkeeping Stop's computing systems or networks or, where the
              Services are hosted by a third party, that third party's computing
              systems and networks
            </p>
            <p className="">
              ii. not use, or misuse, the Services in any way which may impair
              the functionality of the Services or Website, or other systems
              used to deliver the Services or impair the ability of any other
              user to use the Services or Website
            </p>
            <p className="">
              iii. not attempt to gain unauthorized access to any materials
              other than those to which You have been given express permission
              to access or to the computer system on which the Services are
              hosted
            </p>
            <p className="">
              iv. not transmit, or input into the Website, any: files that may
              damage any other person's computing devices or software, content
              that may be offensive, or material or Data in violation of any law
              (including Data or other material protected by copyright or trade
              secrets which You do not have the right to use) and
            </p>
            <p className="">
              v. not attempt to modify, copy, adapt, reproduce, disassemble,
              decompile or reverse engineer any computer programs used to
              deliver the Services or to operate the Website except as is
              strictly necessary to use either of them for normal operation.
            </p>
            <p className="">
              3. By enabling the multi-currency functionality within the
              Services You confirm that You have read and accept the XE.com
              <a href="http://www.xe.com/legal">terms and conditions</a>
              for the use of the XE Currency Datafeed service.
            </p>
            <p className="">
              4. Your use of automated bank account feeds enabled by the Yodlee
              data gathering service (Yodlee Service) from within the Service is
              subject to the
              <a href="/au/about/yodlee-terms/">Yodlee Terms of Use</a>.
            </p>
            <p className="">
              6.
              <span className="">Usage Limitations:</span>
            </p>
            <p className="">
              Use of the Service may be subject to limitations, including but
              not limited to monthly transaction volumes and the number of calls
              You are permitted to make against Xero’s application programming
              interface. Any such limitations will be advised.
            </p>
            <p className="">
              7.
              <span className="">Communication Conditions:</span>
            </p>
            <p className="">
              As a condition of these Terms, if You use any communication tools
              available through the Website (such as any forum, chat room or
              message center), You agree only to use such communication tools
              for lawful and legitimate purposes. You must not use any such
              communication tool for posting or disseminating any material
              unrelated to the use of the Services, including (but not limited
              to): offers of goods or services for sale, unsolicited commercial
              e-mail, files that may damage any other person's computing devices
              or software, content that may be offensive to any other users of
              the Services or the Website, or material in violation of any law
              (including material that is protected by copyright or trade
              secrets which You do not have the right to use).
            </p>
            <p className="">
              When You make any communication on the Website, You represent that
              You are permitted to make such communication. Bookkeeping Stop is
              under no obligation to ensure that the communications on the
              Website are legitimate or that they are related only to the use of
              the Services. As with any other web-based forum, You must exercise
              caution when using the communication tools available on the
              Website. However, Bookkeeping Stop does reserve the right to
              remove any communication at any time in its sole discretion.
            </p>
            <p className="">
              8.<span className="">Indemnity:</span>
            </p>
            <p className="">
              You indemnify Bookkeeping Stop against: all claims, costs, damage
              and loss arising from Your breach of any of these Terms or any
              obligation You may have to Bookkeeping Stop, including (but not
              limited to) any costs relating to the recovery of any Access Fees
              that are due but have not been paid by You.
            </p>
            <p className="">
              4.
              <span className="">Confidentiality and Privacy</span>
            </p>
            <p className="">
              1.
              <span className="">Confidentiality:</span>
            </p>
            <p className="">
              Unless the relevant party has the prior written consent of the
              other or unless required to do so by law:
            </p>
            <p className="">
              1. Each party will preserve the confidentiality of all
              Confidential Information of the other obtained in connection with
              these Terms. Neither party will, without the prior written consent
              of the other, disclose or make any Confidential Information
              available to any person, or use the same for its own benefit,
              other than as contemplated by these Terms.
            </p>
            <p className="">
              2. Each party's obligations under this clause will survive
              termination of these Terms.
            </p>
            <p className="">
              3. The provisions of clauses 4.1.1 and 4.1.2 shall not apply to
              any information which:
            </p>
            <p className="">
              i. is or becomes public knowledge other than by a breach of this
              clause
            </p>
            <p className="">
              ii. is received from a third party who lawfully acquired it and
              who is under no obligation restricting its disclosure
            </p>
            <p className="">
              iii. is in the possession of the receiving party without
              restriction in relation to disclosure before the date of receipt
              from the disclosing party or
            </p>
            <p className="">
              iv. is independently developed without access to the Confidential
              Information.
            </p>
            <p className="">
              2.<span className="">Privacy:</span>
            </p>
            <p className="">
              Bookkeeping Stop maintains a privacy policy that sets out the
              parties’ obligations in respect of personal information. You
              should read that policy at
              <a href="/privacy">www.bookkeepingstop.com/privacy</a>and You will
              be taken to have accepted that policy when You accept these Terms.
            </p>
            <p className="">
              5.
              <span className="">Intellectual Property</span>
            </p>
            <p className="">
              1.<span className="">General</span>:
            </p>
            <p className="">
              Title to, and all Intellectual Property Rights in the Services,
              the Website and any documentation relating to the Services remain
              the property of Bookkeeping Stop (or its licensors).
            </p>
            <p className="">
              2.
              <span className="">Ownership of Data:</span>
            </p>
            <p className="">
              Title to, and all Intellectual Property Rights in, the Data remain
              Your property. However, Your access to the Data is contingent on
              full payment of the Bookkeeping Stop Access Fee when due. You
              grant Bookkeeping Stop a licence to use, copy, transmit, store,
              and back-up Your information and Data for the purposes of enabling
              You to access and use the Services and for any other purpose
              related to provision of services to You.
            </p>
            <p className="">
              3.<span className="">Backup of Data:</span>
            </p>
            <p className="">
              You must maintain copies of all Data inputted into the Service.
              Bookkeeping Stop adheres to its best practice policies and
              procedures to prevent data loss, including a daily system data
              back-up regime, but does not make any guarantees that there will
              be no loss of Data. Bookkeeping Stop expressly excludes liability
              for any loss of Data no matter how caused.
            </p>
            <p className="">
              4.
              <span className="">Third-party applications and your Data.</span>
            </p>
            <p className="">
              If You enable third-party applications for use in conjunction with
              the Services, You acknowledge that Bookkeeping Stop may allow the
              providers of those third-party applications to access Your Data as
              required for the interoperation of such third-party applications
              with the Services. Bookkeeping Stop shall not be responsible for
              any disclosure, modification or deletion of Your Data resulting
              from any such access by third-party application providers.
            </p>
            <p className="">
              6.
              <span className="">Warranties and Acknowledgements</span>
            </p>
            <p className="">
              1.<span className="">Authority:</span>
            </p>
            <p className="">
              You warrant that where You have registered to use the Service on
              behalf of another person, You have the authority to agree to these
              Terms on behalf of that person and agree that by registering to
              use the Service You bind the person on whose behalf You act to the
              performance of any and all obligations that You become subject to
              by virtue of these Terms, without limiting Your own personal
              obligations under these Terms.
            </p>
            <p className="">
              2.
              <span className="">Acknowledgement:</span>
            </p>
            <p className="">You acknowledge that:</p>
            <p className="">
              1. You are authorized to use the Services and the Website and to
              access the information and Data that You input into the Website,
              including any information or Data input into the Website by any
              person you have authorized to use the Service. You are also
              authorized to access the processed information and Data that is
              made available to You through Your use of the Website and the
              Services (whether that information and Data is Your own or that of
              anyone else).
            </p>
            <p className="">
              2.Xero has no responsibility to any person other than You and
              nothing in this Agreement confers, or purports to confer, a
              benefit on any person other than You. If You use the Services or
              access the Website on behalf of or for the benefit of anyone other
              than yourself (whether a body corporate or otherwise) you agree
              that:
            </p>
            <p className="">
              i. You are responsible for ensuring that You have the right to do
              so
            </p>
            <p className="">
              ii. You are responsible for authorizing any person who is given
              access to information or Data, and you agree that Bookkeeping Stop
              has no obligation to provide any person access to such information
              or Data without Your authorization and may refer any requests for
              information to You to address and
            </p>
            <p className="">
              iii. You will indemnify Bookkeeping Stop against any claims or
              loss relating to:
            </p>
            <p className="">
              i. Bookkeeping Stop's refusal to provide any person access to Your
              information or Data in accordance with these Terms,
            </p>
            <p className="">
              ii. Xero’s making available information or Data to any person with
              Your authorization.
            </p>
            <p className="">
              3. The provision of, access to, and use of, the Services is on an
              "as is " basis and at Your own risk.
            </p>
            <p className="">
              4. Bookkeeping Stop does not warrant that the use of the Service
              will be uninterrupted or error free. Among other things, the
              operation and availability of the systems used for accessing the
              Service, including public telephone services, computer networks
              and the Internet, can be unpredictable and may from time to time
              interfere with or prevent access to the Services. Bookkeeping Stop
              is not in any way responsible for any such interference or
              prevention of Your access or use of the Services.
            </p>
            <p className="">
              5. Bookkeeping Stop is not Your accountant and use of the Services
              does not constitute the receipt of accounting advice. If You have
              any accounting questions, please contact an accountant.
            </p>
            <p className="">
              6. It is Your sole responsibility to determine that the Services
              meet the needs of Your business and are suitable for the purposes
              for which they are used.
            </p>
            <p className="">
              7. You remain solely responsible for complying with all applicable
              accounting, tax and other laws. It is Your responsibility to check
              that storage of and access to your Data via the Software and the
              Website will comply with laws applicable to you (including any
              laws requiring you to retain records).
            </p>
            <p className="">
              3.<span className="">No warranties:</span>
            </p>
            <p className="">
              Bookkeeping Stop gives no warranty about the Services. Without
              limiting the foregoing, Bookkeeping Stop does not warrant that the
              Services will meet Your requirements or that it will be suitable
              for any particular purpose. To avoid doubt, all implied conditions
              or warranties are excluded in so far as is permitted by law,
              including (without limitation) warranties of merchantability,
              fitness for purpose, title and non-infringement.
            </p>
            <p className="">
              4.
              <span className="">Consumer guarantees:</span>
            </p>
            <p className="">
              You warrant and represent that You are acquiring the right to
              access and use the Services for the purposes of a business and
              that, to the maximum extent permitted by law, any statutory
              consumer guarantees or legislation intended to protect
              non-business consumers in any jurisdiction does not apply to the
              supply of the Services, the Website or these Terms.
            </p>
            <p className="">
              7.
              <span className="">Limitation of Liability</span>
            </p>
            <p className="">
              1. To the maximum extent permitted by law, Bookkeeping Stop
              excludes all liability and responsibility to You (or any other
              person) in contract, tort (including negligence), or otherwise,
              for any loss (including loss of information, Data, profits and
              savings) or damage resulting, directly or indirectly, from any use
              of, or reliance on, the Service or Website.
            </p>
            <p className="">
              2. If You suffer loss or damage as a result of Bookkeeping Stop's
              negligence or failure to comply with these Terms, any claim by You
              against Bookkeeping Stop arising from Bookkeeping Stop's
              negligence or failure will be limited in respect of any one
              incident, or series of connected incidents, to the Access Fees
              paid by You in the previous 12 months.
            </p>
            <p className="">
              3. If You are not satisfied with the Service, Your sole and
              exclusive remedy is to terminate these Terms in accordance with
              Clause 8.
            </p>
            <p className="">
              8.<span className="">Termination</span>
            </p>
            <p className="">
              1.<span className="">Trial policy</span>
            </p>
            <p className="">
              When You first sign up for access to the Services You can evaluate
              the Services under the defined trial usage conditions, with no
              obligation to continue to use the Services. If You choose to
              continue using the Services thereafter, You will be billed when
              You first add Your billing details into the Services, as set out
              in more detail in the Fee Schedule. If You choose not to continue
              using the Services, You may delete Your organization in the 'My
              Bookkeeping Stop' section of the Services.
            </p>
            <p className="">
              2.
              <span className="">Prepaid Subscriptions</span>
            </p>
            <p className="">
              Bookkeeping Stop will not provide any refund for any remaining
              prepaid period for a prepaid Access Fee subscription.
            </p>
            <p className="">
              3.
              <span className="">No-fault termination:</span>
            </p>
            <p className="">
              These Terms will continue for the period covered by the Access Fee
              paid or payable under clause 3.1. At the end of each billing
              period these Terms will automatically continue for another period
              of the same duration as that period, provided You continue to pay
              the prescribed Access Fee in accordance with the Fee Schedule,
              unless either party terminates these Terms by giving at least one
              month’s advance written notice. If You elect to terminate these
              Terms by providing one month's’ advance written notice, You shall
              be liable to pay all relevant Access Fees up to and including the
              day of termination of these Terms.
            </p>
            <p className="">
              4.<span className="">Breach:</span>
            </p>
            <p className="">If You:</p>
            <p className="">
              1. breach any of these Terms and do not remedy the breach within
              14 days after receiving notice of the breach if the breach is
              capable of being remedied
            </p>
            <p className="">
              2. breach any of these Terms and the breach is not capable of
              being remedied (which includes (without limitation) any breach of
              clause 3.4 or any payment of Access Fees that are not paid in full
              in accordance with the requirements set out in the Fee Schedule)
              or
            </p>
            <p className="">
              3. You or Your business become insolvent or Your business goes
              into liquidation or has a receiver or manager appointed of any of
              its assets or if You become insolvent, or make any arrangement
              with Your creditors, or become subject to any similar insolvency
              event in any jurisdiction,
            </p>
            <p className="">
              Bookkeeping Stop may take any or all of the following actions, at
              its sole discretion:
            </p>
            <p className="">
              4. Terminate this Agreement and Your use of the Services and the
              Website
            </p>
            <p className="">
              5. Suspend for any definite or indefinite period of time, Your use
              of the Services and the Website
            </p>
            <p className="">
              6. Suspend or terminate access to all or any Data.
            </p>
            <p className="">
              7. Take either of the actions in sub-clauses (d), (e) and (f) of
              this clause 8(4) in respect of any or all other persons whom You
              have authorized to have access to Your information or Data.
            </p>
            <p className="">
              For the avoidance of doubt, if payment of any invoice for Access
              Fees due in relation to any of Your Billing Contacts, Billing
              Plans or any of Your Organizations (as defined at clause 3) is not
              made in accordance with the requirements set out in the Fee
              Schedule, Bookkeeping Stop may: suspend or terminate Your use of
              the Service, the authority for all or any of Your Organizations to
              use the Service, or Your rights of access to all or any Data.
            </p>
            <p className="">
              5.<span className="">Accrued Rights:</span>
            </p>
            <p className="">
              Termination of these Terms is without prejudice to any rights and
              obligations of the parties accrued up to and including the date of
              termination. On termination of this Agreement You will:
            </p>
            <p className="">
              1. remain liable for any accrued charges and amounts which become
              due for payment before or after termination and
            </p>
            <p className="">
              2. immediately cease to use the Services and the Website.
            </p>
            <p className="">
              6.
              <span className="">Expiry or termination:</span>
            </p>
            <p className="">
              Clauses 3.1, 3.7, 4, 5, 6, 7, 8 and 10 survive the expiry or
              termination of these Terms.
            </p>
            <p className="">
              9.<span className="">Help Desk</span>
            </p>
            <p className="">
              1.
              <span className="">Technical Problems:</span>
            </p>
            <p className="">
              In the case of technical problems You must make all reasonable
              efforts to investigate and diagnose problems before contacting
              Bookkeeping Stop. If You still need technical help, please check
              the support provided online by Bookkeeping Stop on the Website or
              failing that email us at
              <a href="mailto:info@bookkeepingstop.com">
                info@bookkeepingstop.com
              </a>
              .
            </p>
            <p className="">
              2.
              <span className="">Service availability:</span>
            </p>
            <p className="">
              Whilst Bookkeeping Stop intends that the Services should be
              available 24 hours a day, seven days a week, it is possible that
              on occasions the Services or Website may be unavailable to permit
              maintenance or other development activity to take place.
            </p>
            <p className="">
              If for any reason Bookkeeping Stop has to interrupt the Services
              for longer periods than Bookkeeping Stop would normally expect,
              Bookkeeping Stop will use reasonable endeavours to publish in
              advance details of such activity on the Website.
            </p>
            <p className="">
              10.<span className="">General</span>
            </p>
            <p className="">
              1.
              <span className="">Entire agreement:</span>
            </p>
            <p className="">
              These Terms, together with the Bookkeeping Stop Privacy Policy and
              the terms of any other notices or instructions given to You under
              these Terms of Use, supersede and extinguish all prior agreements,
              representations (whether oral or written), and understandings and
              constitute the entire agreement between You and Bookkeeping Stop
              relating to the Services and the other matters dealt with in these
              Terms.
            </p>
            <p className="">
              2.<span className="">Waiver:</span>
            </p>
            <p className="">
              If either party waives any breach of these Terms, this will not
              constitute a waiver of any other breach. No waiver will be
              effective unless made in writing.
            </p>
            <p className="">
              3.<span className="">Delays:</span>
            </p>
            <p className="">
              Neither party will be liable for any delay or failure in
              performance of its obligations under these Terms if the delay or
              failure is due to any cause outside its reasonable control. This
              clause does not apply to any obligation to pay money.
            </p>
            <p className="">
              4.<span className="">No Assignment:</span>
            </p>
            <p className="">
              You may not assign or transfer any rights to any other person
              without Bookkeeping Stop's prior written consent.
            </p>
            <p className="">
              5.
              <span className="">Governing law and jurisdiction:</span>
            </p>
            <p className="">
              If You are a tax resident of the United States of America or the
              information or Data You are accessing using the Services and the
              Website is solely that of a person who is a tax resident in the
              United States of America at the time that You accept these terms
              then the laws of the State of California, U.S.A govern this
              Agreement and Bookkeeping Stop and You agree that the U.S. Dispute
              Resolution Process described below applies for all disputes
              arising out of or in connection with this Agreement or in any way
              relating to the Service – PLEASE READ THE U.S. DISPUTE RESOLUTION
              PROCESS CAREFULLY AS IT REQUIRES THE USE OF ARBITRATION ON AN
              INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR
              CLASS ACTIONS, AND ALSO LIMITS THE REMEDIES AVAILABLE TO YOU IN
              THE EVENT OF A DISPUTE. If the information or Data You are
              accessing using the Services and the Website is solely that of a
              person who is a tax resident in New Zealand at the time that You
              accept these terms then New Zealand law governs this Agreement and
              You submit to the exclusive jurisdiction of the courts of New
              Zealand for all disputes arising out of or in connection with this
              Agreement. If the information or Data You are accessing using the
              Services and the Website is solely that of a person who is a tax
              resident in Australia at the time that You accept these terms then
              Australian law governs this Agreement and You submit to the
              exclusive jurisdiction of the courts of Australia for all disputes
              arising out of or in connection with this Agreement. In all other
              situations this Agreement is governed by the laws of England and
              Wales and You hereby submit to the exclusive jurisdiction of the
              courts of England and Wales for all disputes arising out of or in
              connection with this Agreement.
            </p>
            <p className="">
              6.<span className="">Severability:</span>
            </p>
            <p className="">
              If any part or provision of these Terms is invalid, unenforceable
              or in conflict with the law, that part or provision is replaced
              with a provision which, as far as possible, accomplishes the
              original purpose of that part or provision. The remainder of this
              Agreement will be binding on the parties.
            </p>
            <p className="">
              7.<span className="">Notices:</span>
            </p>
            <p className="">
              Any notice given under these Terms by either party to the other
              must be in writing by email and will be deemed to have been given
              on transmission. Notices to Bookkeeping Stop must be sent to
              info@bookkeepingstop.com or to any other email address notified by
              email to You by Bookkeeping Stop. Notices to You will be sent to
              the email address which You provided when setting up Your access
              to the Service.
            </p>
            <p className="">
              8.
              <span className="">Rights of Third Parties:</span>
            </p>
            <p className="">
              A person who is not a party to these Terms has no right to benefit
              under or to enforce any term of these Terms.
            </p>
            <p className="">
              11.
              <span className="">
                Additional terms for United States of America
              </span>
            </p>
            <p className="">
              If You are a tax resident of the United States of America or the
              information or Data You are accessing using the Services and the
              Website is solely that of a person who is a tax resident in the
              United States of America, the following additional terms apply:
            </p>
            <p className="">
              <span className="">Consumers</span>
            </p>
            <p className="">
              Bookkeeping Stop is not intended for consumer use (i.e., use for
              personal, family or household purposes).
            </p>
            <p className="">
              <span className="">U.S. Dispute Resolution Process</span>
            </p>
            <p className="">
              PLEASE READ THIS CAREFULLY – IT AFFECTS YOUR RIGHTS.
            </p>
            <p className="">
              The following U.S. Dispute Resolution Process applies to tax
              residents in the United States of America, or if the information
              or Data You are accessing using the Services and the Website is
              that of a person who is a tax resident in the United States of
              America at the time that You accept these terms or if you
              otherwise seek to resolve a dispute in the U.S. If You are a tax
              resident in the United States of America or the information or
              Data You are accessing using the Services and the Website is that
              of a person who is a tax resident in the United States of America
              at the time that You accept these terms, You agree with
              Bookkeeping Stop as follows:
            </p>
            <p className="">a. Summary:</p>
            <p className="">
              Most customer concerns can be resolved quickly and to the
              customer's satisfaction by emailing our customer experience team
              at info@bookkeepingstop.com. IN THE UNLIKELY EVENT THAT OUR
              CUSTOMER EXPERIENCE TEAM IS UNABLE TO RESOLVE A COMPLAINT YOU MAY
              HAVE TO YOUR SATISFACTION (OR IF WE HAVE NOT BEEN ABLE TO RESOLVE
              A DISPUTE WE HAVE WITH YOU AFTER ATTEMPTING TO DO SO INFORMALLY),
              WE EACH AGREE TO RESOLVE THOSE DISPUTES THROUGH BINDING
              ARBITRATION OR SMALL CLAIMS COURT INSTEAD OF IN COURTS OF GENERAL
              JURISDICTION. Arbitration is more informal than a lawsuit in
              court. Arbitration uses a neutral arbitrator instead of a judge or
              jury, allows for more limited discovery than in court, and is
              subject to very limited review by courts. Arbitrators can award
              the same damages and relief that a court can award. ANY
              ARBITRATION UNDER THIS AGREEMENT WILL TAKE PLACE ON AN INDIVIDUAL
              BASIS YOU AGREE WITH Bookkeeping Stop THAT CLASS ARBITRATIONS AND
              CLASS ACTIONS ARE NOT PERMITTED. For any non-frivolous claim that
              does not exceed $10,000, Bookkeeping Stop will pay AAA filing,
              administrative and arbitrator costs. Moreover, in arbitration You
              are entitled to recover attorneys' fees from Bookkeeping Stop to
              at least the same extent as You would be in court.
            </p>
            <p className="">b. U.S. Dispute Resolution Process.</p>
            <p className="">
              ANY DISPUTE OR CLAIM RELATING IN ANY WAY TO THE SERVICE OR THIS
              AGREEMENT WILL BE RESOLVED BY BINDING ARBITRATION, RATHER THAN IN
              COURT, except that You may assert claims in small claims court if
              Your claims qualify. The Federal Arbitration Act governs the
              interpretation and enforcement of this provision the arbitrator
              shall apply California law to all other matters. Notwithstanding
              anything to the contrary, any party to the arbitration may at any
              time seek injunctions or other forms of equitable relief from any
              court of competent jurisdiction. WE EACH AGREE THAT ANY AND ALL
              DISPUTES MUST BE BROUGHT IN THE PARTIES' INDIVIDUAL CAPACITY AND
              NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
              REPRESENTATIVE PROCEEDING. BY ENTERING INTO THIS AGREEMENT AND
              AGREEING TO ARBITRATION, YOU AGREE WITH Bookkeeping Stop THAT YOU
              AND Bookkeeping Stop ARE EACH WAIVING THE RIGHT TO FILE A LAWSUIT
              AND THE RIGHT TO A TRIAL BY JURY. IN ADDITION, YOU AGREE WITH
              Bookkeeping Stop TO WAIVE THE RIGHT TO PARTICIPATE IN A CLASS
              ACTION OR LITIGATE ON A CLASS-WIDE BASIS. YOU AGREE WITH
              Bookkeeping Stop THAT YOU HAVE EXPRESSLY AND KNOWINGLY WAIVED
              THESE RIGHTS.
            </p>
            <p className="">
              To begin an arbitration proceeding, send a letter requesting
              arbitration and describing Your claim to Bookkeeping Stop, Inc. at
              11 Crown Street, Henley, Australia, 2111, Attention: Legal
              Department. Arbitration will be conducted by the American
              Arbitration Association (AAA) before a single AAA arbitrator under
              the AAA’s rules, which are available at www.adr.org or by calling
              1-800-778-7879. Payment of all filing, administration and
              arbitrator fees and costs will be governed by the AAA's rules. In
              addition, Bookkeeping Stop will reimburse those fees and costs for
              claims totaling less than $10,000 unless the arbitrator determines
              the claims are frivolous. Likewise, Bookkeeping Stop will not seek
              its attorneys’ fees or costs in arbitration unless the arbitrator
              determines Your claims or defenses are frivolous. You agree with
              Bookkeeping Stop that You or Bookkeeping Stop may choose to have
              the arbitration conducted by telephone or based on written
              submissions. You agree with Bookkeeping Stop that in person
              arbitration will be conducted in the county in which You reside or
              at another mutually agreeable location. You agree with Bookkeeping
              Stop that the decision of the arbitrator shall be final and not
              appealable, and judgment on the arbitration award may be entered
              in any court having jurisdiction thereof. You agree with
              Bookkeeping Stop that the U.S. Dispute Resolution Process shall
              survive expiration, termination or rescission of this Agreement.
            </p>
            <p>
              <b>
                12. Additional terms for Account Information Services in the
                United Kingdom
              </b>
            </p>
            <p>
              Bookkeeping Stop (UK) Limited is regulated by the Financial
              Conduct Authority as an account information service provider under
              the Payment Services Regulations 2017 (<b>PSRs</b>) (registration
              number 805806). If You’re a Bookkeeping Stop Subscriber or Invited
              User based in the United Kingdom that uses our account information
              services to receive Your transaction information into your
              Bookkeeping Stop account, then these additional terms apply to
              you.
            </p>
            <p>
              The account information services will make up part of the Services
              and any references to Data throughout the Agreement will be deemed
              to include references to 'consolidated information' and
              'transaction information'. If you’re wondering what some of the
              words used in these additional terms mean (like ‘transaction
              information’ and ‘payment account’) check out the key definitions
              below.
            </p>
            <p className="">
              <b>Account information services</b>
            </p>
            <p className="">
              <i>What are they?</i>
              <b> </b>As an account information service provider, Bookkeeping
              Stop can, with Your express permission, receive or access Your
              transaction information from Your payment account with a payment
              service provider in order to flow those transactions into Your
              Bookkeeping Stop account for simple and effective reconciliation.
              Once reconciled, the transaction information becomes consolidated
              information in Your Bookkeeping Stop account. We call this a bank
              feed!
            </p>
            <p className="">
              <i>How do I use them?</i> If You wish to set up a bank feed and
              take advantage of the account information services, You will need
              to explicitly agree to us receiving or accessing Your transaction
              information and, if applicable, accessing Your payment account
              using the authorisation/authentication method prescribed by Your
              payment service provider. In some situations, You may be required
              to periodically re-authorise us to access Your payment account on
              Your behalf.
            </p>
            <p className="">
              <i>How does Bookkeeping Stop use your transaction information?</i>{" "}
              Once we have retrieved your transaction information, we will
              import it into Your Bookkeeping Stop account and only use it to
              provide the Services to You in the normal way. The same applies to
              any consolidated information we create out of that transaction
              information. We’ll process personal data in accordance with our
              privacy policy, available at{" "}
              <a href="http://www.bookkeepingstop.com/privacy">
                www.bookkeepingstop.com/privacy
              </a>
              .
            </p>
            <p className="">
              <i>What charges apply?</i> We charge for provision of the Services
              generally, but do not charge a premium for use of any account
              information services. In some cases, Your payment service provider
              may charge their own fee for providing transaction information.
              Where that’s the case, the fee will either be invoiced by the
              payment service provider directly to You, or we will collect the
              fee from You and pass that through to the payment service
              provider. Check out Bookkeeping Stop Central to see if Your
              payment service provider charges a fee for provision of
              transaction information.
            </p>
            <p className="">
              <i>How do I stop using them? </i>You can stop using account
              information services at any time by following the withdrawal
              method prescribed by Your payment service provider. You may still
              use the Services without the account information services.
            </p>
            <p className="">
              <b>Accuracy</b>
            </p>
            <p className="">
              Any transaction information imported into Your Bookkeeping Stop
              account will be a reflection of the transaction information held
              with Your payment service provider (e.g., the information held in
              your online banking transactions). We aren’t responsible for any
              inaccuracy or incompleteness of this information.
            </p>
            <p className="">
              <b>Liability</b>
            </p>
            <p className="">
              For the purposes of these additional terms relating to the account
              information services, our maximum aggregate liability to You
              arising out of or in connection with the unauthorised disclosure
              of Your sensitive payment data, where sensitive payment data is
              used to commit fraud, shall be limited to two times the fees paid
              by You to us in the preceding 12 months. Otherwise, the liability
              limitations in the Agreement will apply to the account information
              services in the normal way as part of the Services.
            </p>
            <p className="">
              <b>Communications under the PSRs</b>
            </p>
            <p className="">
              As a technology company, we prefer to communicate with you online.
              If we are required to communicate with You under the PSRs, we
              will:
            </p>
            <p className="">
              a. Communicate general messages with a notification on the Website
              and
            </p>
            <p className="">
              b. Communicate messages specific to You, including in the event of
              actual or suspected fraud, to the email address provided by You
              during the registration process.
            </p>
            <p className="">
              These additional terms applying to account information services
              are concluded in English and all information and notifications
              under these additional terms will also be in English.
            </p>
            <p className="">
              <b>Definitions</b>
            </p>
            <p className="">
              “Consolidated information” means a form of information created
              using Your transaction information, as part of the account
              information service.
            </p>
            <p className="">
              “Payment account” means an account that is used for the execution
              of payment transactions, such as a current account.
            </p>
            <p className="">
              “Payment service provider” means an entity which provides payment
              services as set out in the PSRs (e.g., a bank).
            </p>
            <p className="">
              “Sensitive payment data” means Your information that could be
              reasonably used to carry out fraud, including Your personalised
              security credentials (but excluding, in relation to Your payment
              account(s), the name of the account holder and the account
              number).
            </p>
            <p className="">
              “Transaction information” means the information We access from
              Your payment account(s) concerning Your payment transactions.
            </p>
            <br />
            <p className="">
              If you have any concerns – please get in touch with us at
              <a href="mailto:info@bookkeepingstop.com" className="text-dark">
                info@bookkeepingstop.com
              </a>{" "}
              and we’ll be happy to discuss with you further.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Terms;
