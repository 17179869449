import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

class AddOns extends Component {
  render() {
    const { addOns } = this.props;

    return (
      <div>
        <div className="row">
          <div className="d-flex flex-column mx-auto">
            <div className="col col-stretch cen-mid">
              <h4 cl assName="p-2">
                Add Ons
              </h4>
            </div>
            <div className="col-stretch cen-top">
              <p className="p-2 font-weight-normal">
                Optional adds ons available in additional to services provided
                in packages above.
              </p>
            </div>
          </div>
        </div>

        <div className="card-deck m-4 d-flex justify-content-center">
          {addOns &&
            addOns.map((addOn, index) => {
              return (
                <div
                  key={index}
                  className="card shadow-sm text-center p-0 col-md-8 col-lg-6 col-xl-4"
                >
                  <div className="card-header">
                    <h4 className="p-2 font-weight-normal">
                      {addOn.addOnName}
                    </h4>
                  </div>
                  <div className="card-body m-0 mt-2">
                    <h3 className="p-2 card-title pricing-card-title">
                      $
                      {addOn.addOnPrice
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                    </h3>
                    <ul className="list-unstyled m-0 mt-2">
                      {addOn.addOnDescription}
                    </ul>
                  </div>
                  <div className="card-footer bg-transparent border-white mb-4">
                    <a
                      className="btn btn-md btn-outline-primary p-2"
                      href="/pricing"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      /*
                  <li>- - -</li>
                  <div className="text-muted">
                    <li>20 users included</li>
                    <li>10 GB of storage</li>
                    <li>Priority email support</li>
                    <li>Help center access</li>
                  </div>
                  {this.state.showSmall ? (
                    <div>
                      <li>20 users included</li>
                      <li>10 GB of storage</li>
                      <li>Priority email support</li>
                      <li>Help center access</li>
                    </div>
                  ) : null}
                </ul>
                <button
                  className="textButton"
                  onClick={() => this.expandSmall()}
                  id="moreButton"
                >
                  {this.state.showSmall ? (
                    <div>View less &uarr;</div>
                  ) : (
                    <div>View more &darr;</div>
                  )}
                </button> */
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addOns: state.firestore.ordered.addOns,
  };
};

export default compose(
  firestoreConnect([
    {
      collection: "addOns",
      where: ["addOnPrice", ">=", "1"],
      orderBy: ["addOnPrice", "asc"],
    },
  ]),
  connect(mapStateToProps)
)(AddOns);
