import React, { Component } from "react";
import { connect } from "react-redux";
import { signIn } from "../../actions/authActions";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router";

class SignIn extends Component {
  state = {
    email: "",
    password: ""
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.signIn(this.state);
    //this.props.history.push("/clientHome");
  };

  render() {
    const { auth, authError } = this.props;
    if (auth.uid) return <Redirect to="/clientHome" />;

    return (
      <div className="container text-center">
        <div className="y-100" />
        <div className="flex-column align-items-center p-4 bg-white">
          <form
            className="form-signin col-xs-6 col-sm-6 col-md-6 col-lg-6 mx-auto"
            onSubmit={this.handleSubmit}
            to="/clientHome"
          >
            <div className="text-center mb-4">
              <h1 className="h3 mb-3 font-weight-normal">Sign In</h1>
            </div>
            <div>
              {/* FIX - red line appears when bg-white removed below */}
            </div>
            <div
              className="alert-danger rounded col-xs-6 col-sm-6 col-md-6 col-lg-6 mx-auto bg-white"
              style={{ color: "#ff0000" }}
            >
              {authError ? <p>{authError}</p> : null}
            </div>
            <div className="form-label-group mt-2">
              <input
                type="email"
                id="email"
                className="form-control"
                placeholder="Email"
                required
                onChange={this.handleChange}
              />
            </div>
            <br />
            <div className="form-label-group">
              <input
                type="password"
                id="password"
                className="form-control"
                placeholder="Password"
                required
                onChange={this.handleChange}
              />
            </div>
            <br />
            <input
              className="btn btn-md btn-primary"
              type="submit"
              value="Sign In"
            />
            <br />
            <br />
            <div className="checkbox mb-3">
              <small>
                <a href="./">Forgot password?</a>
              </small>
            </div>
          </form>
        </div>
        <div className="y-100" />
      </div>
    );
  }
}

/* <input type="checkbox" value="remember-me" checked /> Keep me
                signed in */

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignIn)
);
