import React, { Component } from "react";
//import moment from "moment";
import { connect } from "react-redux";
import {
  editFeature,
  addFeature,
  deleteFeature,
  cleanFeature
} from "../../actions/viewActions";
//import { Redirect } from "react-router-dom";
import { compose } from "redux";

class ViewFeatures extends Component {
  state = {
    id: this.props.feature.id,
    plan: this.props.feature.plan,
    size: this.props.feature.size,
    name: this.props.feature.name,
    description: this.props.feature.description
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
    console.log(this.state);
  };

  handleDelete = e => {
    e.preventDefault();
    console.log(this.state);
    this.props.deleteFeature(this.state);
  };

  handleClean = e => {
    e.preventDefault();
    console.log(this.state);
    this.props.cleanFeature(this.state);
  };

  render() {
    const { feature } = this.props;
    console.log(this.state);

    return (
      <form
        className="form-horizontal mx-2"
        onSubmit={this.handleSubmit}
        to="/view"
      >
        <table className="table table-responsive-lg table-borderless m-0 p-0">
          <tbody>
            <tr>
              <td className="p-1">
                <select
                  type="number"
                  id="plan"
                  className="form-control form-control-sm"
                  defaultValue={feature.plan}
                  onChange={this.handleChange}
                >
                  <option />
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                </select>
              </td>
              <td className="p-1">
                <input
                  type="number"
                  id="size"
                  className="form-control form-control-sm"
                  defaultValue={feature.size}
                  onChange={this.handleChange}
                />
              </td>
              <td className="p-1">
                <input
                  type="text"
                  id="name"
                  className="form-control form-control-sm"
                  defaultValue={feature.name}
                  onChange={this.handleChange}
                />
              </td>
              <td className="p-1">
                <textarea
                  type="text"
                  id="description"
                  className="form-control form-control-sm"
                  defaultValue={feature.description}
                  onChange={this.handleChange}
                />
              </td>
              <td className="p-1">
                <button
                  className="btn btn-sm btn-success form-control-sm"
                  onClick={this.handleClean.bind(this)}
                >
                  <i className="fas fa-check" />
                </button>{" "}
                {"  "}
                <button
                  className="btn btn-sm btn-danger form-control-sm"
                  onClick={this.handleDelete.bind(this)}
                >
                  <i className="far fa-trash-alt" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  }
}

const mapStateToProps = state => {
  console.log(state);
  return {
    auth: state.firebase.auth,
    features: state.firestore.ordered.features
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editFeature: props => dispatch(editFeature(props)),
    addFeature: props => dispatch(addFeature(props)),
    deleteFeature: props => dispatch(deleteFeature(props)),
    cleanFeature: props => dispatch(cleanFeature(props))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ViewFeatures);
