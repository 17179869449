import React, { Component } from "react";
import { connect } from "react-redux";
//import { Redirect } from "react-router-dom";
import { uploadFile } from "../../actions/uploadActions";

class UploadFile extends Component {
  handleChange = event => {
    this.setState({
      file: event.target.files[0]
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    console.log(this.state);
    this.props.uploadFile(this.state);
  };

  render() {
    //const { auth } = this.props;
    //if (!auth.uid) return <Redirect to="/signin" />;
    console.log(this.state);

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="mx-auto col">
            <h4 className="text-center">Files</h4>
            <small
              className="p-2 cen-mid font-italic text-muted"
              style={{ lineHeight: "2" }}
            >
              Can only import .csv files
            </small>
          </div>
        </div>
        <br />
        <form className="form-horizontal " onSubmit={this.handleSubmit}>
          <div className="form-group row cen-mid">
            <div className="custom-file col-sm-4 col-md-4 col-lg-4">
              <input
                type="file"
                className="custom-file-input"
                id="fileButton"
                onChange={this.handleChange}
              />
              <label
                className="custom-file-label text-left"
                for="inputGroupFile01"
              >
                Choose file
              </label>
            </div>
          </div>
          <div className="form-group row cen-mid">
            <input
              className="btn btn-md btn-primary"
              type="submit"
              value="Upload"
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uploadFile: props => dispatch(uploadFile(props))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadFile);
