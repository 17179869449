import React, { Component } from "react";
import rp from "request-promise";
import $ from "cheerio";

class Parser extends Component {
  state = {
    proxyurl: "https://cors-anywhere.herokuapp.com/",
    base: "http://handbook.uts.edu.au/subjects/details/",
    input: null,
    html: ".html",
    subjectName: "",
    creditPoints: [],
    cp: "",
    titles: [],
    types: [],
    groupwork: [],
    weights: []
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
    console.log(this.state);
  };

  handleSubmit = e => {
    e.preventDefault();
    var proxyurl = this.state.proxyurl;
    var base = this.state.base;
    var input = this.state.input;
    var html = this.state.html;
    var url = proxyurl + base + input + html;
    rp(url)
      .then(html => {
        var sn = $("h1", html).text();
        var cp = $("em", html);
        console.log(cp);
        this.setState({
          creditPoints: cp
        });
        var c = this.state.creditPoints;
        console.log(c);
        for (let i = 0; i < c.length; i++) {
          var c1 = c[i].children["0"].data;
          var regex = new RegExp("Credit points:");
          if (regex.test(c1)) {
            var c2 = c[i].next.data;
            console.log(c2);
          }
        }
        var title = $("h4", html);
        var type = $(".assessmentTaskTableMainHeading", html);
        var group = $(".assessmentTaskTableMainHeading", html);
        var weight = $("td", html);
        this.setState({
          subjectName: sn,
          cp: c2,
          titles: title,
          types: type,
          groupwork: group,
          weights: weight
        });
        this.createArray();
        console.log("Success");
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  createNumbers = () => {
    let numbers = [];
    var t = this.state.titles;
    for (let i = 0; i < t.length; i++) {
      numbers.push(i + 1);
    }
    return numbers;
  };

  createTitles = () => {
    let titles = [];
    var t = this.state.titles;
    for (let i = 0; i < t.length; i++) {
      var titem = t[i].children["0"].data;
      if (titem != null) {
        var t1 = titem.slice(20);
        titles.push(t1);
      }
    }
    return titles;
  };

  createTypes = () => {
    let types = [];
    var ty = this.state.types;
    for (let i = 0; i < ty.length; i++) {
      var tyitem = ty[i].children["0"].data;
      var regex = new RegExp("Type:");
      if (regex.test(tyitem)) {
        var ty1 = ty[i].next.next.children["0"].data;
        types.push(ty1);
      }
    }
    return types;
  };

  createGroupwork = () => {
    let groupwork = [];
    var g = this.state.groupwork;
    for (let i = 0; i < g.length; i++) {
      var gitem = g[i].children["0"].data;
      var regex = new RegExp("Groupwork:");
      if (regex.test(gitem)) {
        var g1 = g[i].next.next.children["0"].data;
        groupwork.push(g1);
      }
    }
    return groupwork;
  };

  createWeights = () => {
    let weights = [];
    var w = this.state.weights;
    for (let i = 0; i < w.length; i++) {
      var witem = w[i].children["0"].data;
      var regex = new RegExp("%");
      if (regex.test(witem)) {
        var w1 = witem;
        weights.push(w1);
      }
    }
    return weights;
  };

  createArray = () => {
    let children = [];
    let numbers = this.createNumbers();
    let titles = this.createTitles();
    let types = this.createTypes();
    let groupwork = this.createGroupwork();
    let weights = this.createWeights();
    let count = 0;
    if (weights != null) {
      for (let i = 0; i < weights.length; i++) {
        var n = numbers[i];
        var t = titles[i];
        var ty = types[i];
        var g = groupwork[i];
        var w = weights[i];
        var wNum = parseInt(w.replace("%", "").trim());
        count = count + wNum;
        children.push(
          <tr>
            <th scope="row">{n}</th>
            <td>{t}</td>
            <td>{ty}</td>
            <td>{g}</td>
            <td>{w}</td>
          </tr>
        );
      }
    }
    let totals = (
      <tr>
        <th scope="row">
          <br />
        </th>
        <td>
          <br />
        </td>
        <td>
          <br />
        </td>
        <td>
          <br />
        </td>
        <td>{count ? count + "%" : null} </td>
      </tr>
    );
    children.push(totals);
    return children;
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="y-100" />{" "}
        <div className="p-4 cen-mid">
          <h5 className="">Find your assesments below</h5>
        </div>
        <form className="form-horizontal m-4" onSubmit={this.handleSubmit}>
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="text"
            >
              Subject Number
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="text"
                id="input"
                className="form-control"
                placeholder="Enter subject number"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row cen-mid">
            <input
              className="btn btn-md btn-primary"
              type="submit"
              value="Submit"
            />
          </div>
        </form>
        <div className="y-50" />
        <div className="p-2 cen-mid">
          <h5 className="">
            {this.state.subjectName ? this.state.subjectName : null}
          </h5>
        </div>
        <div className="pb-4 cen-mid">
          <h5 className="font-italic">
            {this.state.cp ? this.state.cp : null}
          </h5>
        </div>
        <div>
          {this.state.subjectName ? (
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Task</th>
                  <th scope="col">Type</th>
                  <th scope="col">Groupwork</th>
                  <th scope="col">Weight</th>
                </tr>
              </thead>
              <tbody>{this.createArray()}</tbody>
            </table>
          ) : null}
        </div>
        <div className="y-100" />
      </div>
    );
  }
}

export default Parser;
