import React, { Component } from "react";
import { connect } from "react-redux";
//import { Redirect } from "react-router-dom";
import UploadFile from "./UploadFile";
import UploadLedger from "./UploadLedger";

class Upload extends Component {
  render() {
    //const { auth } = this.props;
    //if (!auth.uid) return <Redirect to="/home" />;

    return (
      <div className="container-fluid">
        <div className="y-100" />
        <UploadFile />
        <div className="y-100" />
        <UploadLedger />
        <div className="y-100" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

export default connect(mapStateToProps)(Upload);
