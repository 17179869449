import React, { Component } from "react";

class ActivityLastMonth extends Component {
  createJune = () => {
    let june = [];
    const { entry } = this.props;
    // Outer loop to create parent
    for (let i = 0; i < 1; i++) {
      let children = [];

      //Inner loop to create children
      for (let j = 0; j < 500; j++) {
        if (entry[j][0] === undefined || entry[j][0] === null) {
          break;
        } else {
          var date = new Date();
          var timeFrame = date.setMonth(date.getMonth() - 6);
          var x = new Date(timeFrame);
          //var y = x.getMonth();
          var d = entry[j][0];
          var f = d.slice(3, 6) + "01/" + d.slice(8, 10);
          var g = Date.parse(f);
          var a = new Date(g);
          //var b = a.getMonth();
          var h = entry[j][1];
          var k = Number(h);
          var debit;
          var credit;

          if (k > 0) {
            debit = <br />;
            credit = k;
          } else {
            debit = k;
            credit = <br />;
          }

          if (a > x) {
            children.push(
              <tr>
                <td>{d}</td>
                <td>{entry[j][2]}</td>
                <td>{debit}</td>
                <td>{credit}</td>
                <td>{<br />}</td>
                <td>
                  {
                    <a
                      className=""
                      href="https://firebasestorage.googleapis.com/v0/b/bkstop-a40d9.appspot.com/o/8OrYTtiSGGUcAZKr7v2o1fURI1w1%2FInvoiceS-31849.pdf?alt=media&token=d4ff277d-9c30-43e4-917b-7c91724aca4f"
                      target="_parent"
                      rel="noopener noreferrer"
                    >
                      <i className="fas fa-file-pdf mr-1 mb-1" />
                    </a>
                  }
                </td>
              </tr>
            );
          }
        }
      }
      //Create the parent and add the children
      june.push(children);
    }
    return june;
  };

  creditSum = () => {
    const { entry } = this.props;
    let creditSum = 0;
    // Outer loop to create parent
    for (let i = 0; i < 1; i++) {
      //Inner loop to create children
      for (let j = 0; j < 500; j++) {
        if (entry[j][0] === undefined || entry[j][0] === null) {
          break;
        } else {
          var h = entry[j][1];
          var k = Number(h);
          if (k > 0) {
            creditSum = creditSum + k;
          }
        }
      }
    }
    return creditSum.toFixed(2);
  };

  debitSum = () => {
    const { entry } = this.props;
    let debitSum = 0;
    // Outer loop to create parent
    for (let i = 0; i < 1; i++) {
      //Inner loop to create children
      for (let j = 0; j < 500; j++) {
        if (entry[j][0] === undefined || entry[j][0] === null) {
          break;
        } else {
          var h = entry[j][1];
          var k = Number(h);
          if (k < 0) {
            debitSum = debitSum + k;
          }
        }
      }
    }
    return debitSum.toFixed(2);
  };

  render() {
    //const { entry } = this.props;
    console.log(this.props);
    console.log(this.createJune());
    //var i = 0;
    return (
      <div className="container">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Decription</th>
              <th scope="col">Debit</th>
              <th scope="col">Credit</th>
              <th scope="col">Balance</th>
              <th>{<br />}</th>
            </tr>
          </thead>
          <tbody>
            {this.createJune()}
            <tr>
              <td>{<br />}</td>
              <td>{<br />}</td>
              <td>{this.debitSum()}</td>
              <td>{this.creditSum()}</td>
              <td>{<br />}</td>
              <td>{<br />}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default ActivityLastMonth;
