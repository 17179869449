import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { signUp } from "../../actions/authActions";

class SignUp extends Component {
  state = {
    firstName: "",
    lastName: "",
    businessName: "",
    employees: "",
    phoneNumber: "",
    email: "",
    referralCode: "",
    plan: "",
    password: "",
    rePassword: ""
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { password, rePassword } = this.state;
    if (password !== rePassword) {
      alert("Passwords don't match");
    } else {
      this.props.signUp(this.state);
    }
    //this.props.history.push("/clientHome");
  };

  render() {
    const { auth, authError } = this.props;
    if (auth.uid) return <Redirect to="/clientHome" />;

    return (
      <div className="container-fluid">
        <div className="y-50" />
        <form
          className="form-horizontal m-4"
          onSubmit={this.handleSubmit}
          to="/clientHome"
        >
          <div className="y-100 cen-mid">
            <h4 className="">Create your account</h4>
          </div>
          <div>{/* FIX - red line appears when bg-white removed below */}</div>
          <div
            className="alert-danger rounded col-xs-8 col-sm-8 col-md-8 col-lg-6 mx-auto bg-white"
            style={{ color: "#ff0000" }}
          >
            {authError ? <p>{authError}</p> : null}
          </div>

          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="firstName"
            >
              First name:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="text"
                id="firstName"
                className="form-control"
                placeholder="Enter first name"
                autoFocus
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="lastName"
            >
              Last name:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="text"
                id="lastName"
                className="form-control"
                placeholder="Enter last name"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="phoneNumber"
            >
              Phone number:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="tel"
                id="phoneNumber"
                className="form-control"
                placeholder="Enter phone number"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="email"
            >
              Email:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="email"
                id="email"
                className="form-control"
                placeholder="Enter email"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="businessName"
            >
              Business Name:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="text"
                id="businessName"
                className="form-control"
                placeholder="Enter business name"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="employees"
            >
              Number of Employees:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="text"
                id="employees"
                className="form-control"
                placeholder="Enter number of employees"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="plan"
            >
              Choose Plan:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <select
                type="text"
                id="plan"
                className="form-control"
                required
                onChange={this.handleChange}
              >
                <option />
                <option value="basic">Basic @ $250/monthly</option>
                <option value="standard">Standard @ $500/monthly</option>
                <option value="enterprise">Enterprise @ $1,000/monthly</option>
              </select>
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="referralCode"
            >
              Referral Code:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="text"
                id="referralCode"
                className="form-control"
                placeholder="Enter referral code"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="text"
            >
              Password:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="password"
                id="password"
                className="form-control"
                placeholder="Enter password"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="text"
            >
              Re-enter Password:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="password"
                id="rePassword"
                className="form-control"
                placeholder="Re-enter password"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row cen-mid">
            <input
              className="btn btn-md btn-primary"
              type="submit"
              value="Submit"
            />
          </div>
        </form>
        <div className="y-100" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
    //stripeError: state.auth.stripeError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signUp: newUser => dispatch(signUp(newUser))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp);
