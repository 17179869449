import React, { Component } from "react";
import { connect } from "react-redux";
//import { Redirect } from "react-router-dom";
import Papa from "papaparse";
import { draftLedger } from "../../actions/ledgerActions";

class UploadLedger extends Component {
  constructor() {
    super();
    this.state = {
      csvfile: undefined
    };
    this.updateData = this.updateData.bind(this);
  }

  handleChange = event => {
    this.setState({
      csvfile: event.target.files[0]
    });
  };

  importCSV = () => {
    const { csvfile } = this.state;
    Papa.parse(csvfile, {
      complete: this.updateData,
      header: false,
      dynamicTyping: true
    });
  };

  updateData(result) {
    var data = result.data;
    console.log(data);
    this.props.draftLedger(data);
  }

  render() {
    //const { auth } = this.props;
    //if (!auth.uid) return <Redirect to="/signin" />;
    console.log(this.state.csvfile);

    return (
      <div className="container-fluid">
        <div className="mx-auto col">
          <h4 className="text-center">Ledger</h4>
          <small
            className="p-2 cen-mid font-italic text-muted"
            style={{ lineHeight: "2" }}
          >
            Can only import .csv files
          </small>
        </div>
        <br />
        <div className="row">
          <div className="mx-auto col-xs-8 col-sm-6 col-lg-4">
            <div className="input-group mb-3">
              <div className="custom-file">
                <input
                  className="csv-input"
                  type="file"
                  ref={input => {
                    this.filesInput = input;
                  }}
                  name="file"
                  placeholder={null}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col cen-mid">
            <button
              className="btn btn-md btn-primary m-2 cen-mid"
              onClick={this.importCSV}
            >
              Import
            </button>
            <br />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    draftLedger: props => dispatch(draftLedger(props))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadLedger);
