import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

class ServicesSm extends Component {
  render() {
    const { features } = this.props;
    return (
      <div className="container-fluid">
        <div className="row d-flex flex-column mx-auto">
          <div className="col-stretch cen-bot">
            <h4 className="">Basic Services</h4>
          </div>
          <div className="col-stretch cen-top">
            <small className="font-weight-normal">*Included in all plans</small>
          </div>
        </div>

        <div className="row justify-content-center mt-4">
          {features &&
            features.map((feature, index) => {
              if (feature.plan === "1") {
                return (
                  <div key={index} className="col-sm-12 col-md-6 col-lg-4">
                    <div className="card p-2 cen-mid circle-card">
                      <img
                        className="card-img-top"
                        alt={feature.name}
                        src={require("../../img/services/" +
                          feature.size +
                          ".svg")}
                        style={{ width: "100px", height: "100px" }}
                      />
                      <div className="card-body">
                        <h5 className="card-title text-capitalize font-italic">
                          {feature.name}
                        </h5>
                        <p className="card-text text-muted">
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return <div />;
              }
            })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    features: state.firestore.ordered.features
  };
};

export default compose(
  firestoreConnect([
    {
      collection: "features",
      orderBy: ["size", "asc"]
    }
  ]),
  connect(mapStateToProps)
)(ServicesSm);
