import React from "react";
//import CoverClient from "./CoverClient";
import CoverPublic from "./CoverPublic";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";

const Cover = props => {
  const { auth } = props;

  const layout = auth.uid /*<Router>
      <Route
        exact
        path={[
          "/signin",
          "/signup",
          "/clientHome",
          "/account",
          "/analytics",
          "/activity",
          "/reports",
          "/support"
        ]}
        component={CoverClient}
      />
    </Router>*/ ? null : (
    <Router>
      <Route
        exact
        path={[
          "/",
          "/home",
          "/about",
          "/services",
          "/pricing",
          "/contact",
          "/clients"
        ]}
        component={CoverPublic}
      />
    </Router>
  );
  return <div>{layout}</div>;
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(Cover);
