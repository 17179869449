import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import registerServiceWorker from "./registerServiceWorker";
// import { Provider, useSelector } from "react-redux";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers/rootReducer";
import thunk from "redux-thunk";
// import { reactReduxFirebase, getFirebase } from "react-redux-firebase";
// import {
//   ReactReduxFirebaseProvider,
//   getFirebase,
//   isLoaded,
// } from "react-redux-firebase";
import { ReactReduxFirebaseProvider, getFirebase } from "react-redux-firebase";
import {
  createFirestoreInstance,
  reduxFirestore,
  getFirestore,
} from "redux-firestore";
import fbConfig from "./config/fbConfig";
import firebase from "firebase/app";

const rrfConfig = {
  userProfile: "users",
  attachAuthIsReady: true,
  useFirestoreForProfile: true,
};

const store = createStore(
  rootReducer,
  compose(
    // reactReduxFirebase(fbConfig, rrfConfig),
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
    reduxFirestore(fbConfig)
  )
);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

// function AuthIsLoaded({ children }) {
//   const auth = useSelector((state) => state.firebase.auth);
//   if (!isLoaded(auth)) return <div>Loading Screen...</div>;
//   return children;
// }

// store.firebaseAuthIsReady.then(() => {
ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      {/* <AuthIsLoaded> */}
      <App />
      {/* </AuthIsLoaded> */}
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
// });
