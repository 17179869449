import authReducer from "./authReducer";
import ticketReducer from "./ticketReducer";
import paymentReducer from "./paymentReducer";
import profileReducer from "./profileReducer";
import contactReducer from "./contactReducer";
import stripeReducer from "./stripeReducer";
import uploadReducer from "./uploadReducer";
import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";

const rootReducer = combineReducers({
  auth: authReducer,
  ticket: ticketReducer,
  payment: paymentReducer,
  profile: profileReducer,
  contact: contactReducer,
  stripe: stripeReducer,
  upload: uploadReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer
});

export default rootReducer;
