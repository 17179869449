import React, { Component } from "react";
import { connect } from "react-redux";
import { updateProfile } from "../../actions/profileActions";
import { Redirect } from "react-router-dom";

class ChangePlan extends Component {
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.updateProfile(this.state);
  };

  render() {
    const { profile, profileError, auth, profileUpdateSuccess } = this.props;
    console.log(this.props);

    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div className="container-fluid">
        <form
          className="form-horizontal m-4"
          onSubmit={this.handleSubmit}
          to="/account"
        >
          <div className="y-50 cen-mid">
            <h4 className="">Change Plan</h4>
          </div>
          <div className="cen-mid">
            {profileError ? (
              <div className="text-danger pb-4">
                <p>{profileError}</p>
              </div>
            ) : null}
          </div>
          <div className="pb-4 cen-mid">
            {profileUpdateSuccess ? (
              <div className="text-primary">
                <p>{profileUpdateSuccess}</p>
              </div>
            ) : null}
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="text"
            >
              Current plan:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <p className="text-capitalize">{profile.plan}</p>
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="plan"
            >
              Change Plan:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <select
                type="text"
                id="plan"
                className="form-control"
                required
                onChange={this.handleChange}
              >
                <option value="" selected disabled hidden>
                  Choose a Plan
                </option>
                <option value="basic">Basic @ $250/monthly</option>
                <option value="standard">Standard @ $500/monthly</option>
                <option value="enterprise">Enterprise @ $1,000/monthly</option>
              </select>
            </div>
          </div>
          <br />
          <div className="form-group row cen-mid">
            <input
              className="btn btn-md btn-primary"
              type="submit"
              value="Change Plan"
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log(state);
  return {
    profile: state.firebase.profile,
    profileUpdateSuccess: state.profile.profileUpdateSuccess,
    profileError: state.profile.profileError,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProfile: props => dispatch(updateProfile(props))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePlan);
