import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { signUp } from "../../actions/authActions";

class SignUpOne extends Component {
  state = {
    firstName: "",
    lastName: "",
    businessName: "",
    employees: "",
    phoneNumber: "",
    email: "",
    password: ""
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.signUp(this.state);
  };

  render() {
    const { auth, authError } = this.props;
    if (auth.uid) return <Redirect to="/dashboard" />;

    return (
      <div className="container-fluid">
        <form
          className="form-signin col-xs-8 col-sm-8 col-md-8 col-lg-8 mx-auto"
          onSubmit={this.handleSubmit}
          to="/signupthree"
        >
          <div className="y-50" />
          <div className="align-self-left p-2">
            <p className="">
              Step <strong>2</strong> of <strong>3</strong>
            </p>
          </div>
          <br />
          <div className="cen-mid p-2">
            <h1 className="">Create your account</h1>
          </div>
          <br />
          <div
            className="alert-danger rounded col-xs-6 col-sm-6 col-md-6 col-lg-6 mx-auto"
            style={{ color: "#ff0000" }}
          >
            {authError ? <p>{authError}</p> : null}
          </div>

          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 d-flex flex-column mx-auto">
            <div className="form-label-group">
              <input
                type="text"
                id="firstName"
                className="form-control"
                placeholder="First name"
                required
                onChange={this.handleChange}
              />
            </div>
            <br />
            <div className="form-label-group">
              <input
                type="text"
                id="lastName"
                className="form-control"
                placeholder="Last name"
                required
                onChange={this.handleChange}
              />
            </div>
            <br />
            <div className="form-label-group">
              <input
                type="text"
                id="businessName"
                className="form-control"
                placeholder="Business name"
                required
                onChange={this.handleChange}
              />
            </div>
            <br />
            <div className="form-label-group">
              <input
                type="text"
                id="employees"
                className="form-control"
                placeholder="Number of Employees"
                required
                onChange={this.handleChange}
              />
            </div>
            <br />
            <div className="form-label-group">
              <input
                type="tel"
                id="phoneNumber"
                className="form-control"
                placeholder="Phone number"
                required
                onChange={this.handleChange}
              />
            </div>
            <br />
            <div className="form-label-group">
              <input
                type="email"
                id="email"
                className="form-control"
                placeholder="Email"
                required
                onChange={this.handleChange}
              />
            </div>
            <br />
            <div className="form-label-group">
              <input
                type="password"
                id="password"
                className="form-control"
                placeholder="Password"
                required
                onChange={this.handleChange}
              />
            </div>
            <br />
            <div className="form-label-group">
              <input
                type="password"
                id="password"
                className="form-control"
                placeholder="Re-enter password"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="y-50" />
          <div className="">
            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto">
              <a
                className="btn btn-lg btn-block btn-primary"
                href="/signupthree"
              >
                Continue
              </a>
            </div>
          </div>
        </form>
        <div className="y-100" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signUp: newUser => dispatch(signUp(newUser))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpOne);
