import React, { Component } from "react";
import Plans from "./Plans";
import AddOns from "./AddOns";

class Pricing extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="y-50" />
        <div className="y-50 d-none d-lg-block" />
        <Plans />
        <div className="row ml-4 mr-4">
          <div className="d-flex flex-column mx-auto">
            <div className="col-stretch cen-top">
              <small className="font-weight-normal">
                *All plans include a 30 day money back guarantee. Signup
                requires a minimum 3 month term for all subscriptions. Billing
                commences on the first day of the subscription and recurs
                monthly until plan ceases. If cancellation occurs, service will
                continue until completion of billing cycle. All billing is
                handled by a third party online payment processing system.
              </small>
            </div>
          </div>
        </div>
        <div className="y-100" />
        <AddOns />
        <div className="y-50" />
        <div className="y-50 d-none d-md-block" />
        {/* <div className="row y-300 d-none d-md-flex">
          <div className="d-flex flex-column mx-auto">
            <div className="col-stretch lef-mid">
              <h4 className="p-2">Not sure which plan suits your needs?</h4>
            </div>
            <div className="col-stretch rig-mid">
              <h4 className="p-2 text-muted">
                Want to negotiate a better price?
              </h4>
            </div>
            <div className="col-stretch cen-mid">
              <h4 className="font-weight-normal">
                <a className="p-2 no-decoration" href="tel:+61290300315">
                  Call Us
                </a>
                and we can discuss things with you further.
              </h4>
            </div>
          </div>
        </div>
        <div className="row y-200 d-md-none">
          <div className="d-flex flex-column mx-auto">
            <div className="col-stretch lef-mid">
              <p className="p-2 font-6">
                Not sure which plan suits your needs?
              </p>
            </div>
            <div className="col-stretch rig-mid">
              <p className="p-2 font-6 text-muted">
                Want to negotiate a better price?
              </p>
            </div>
            <div className="col-stretch cen-mid">
              <p className="p-2 font-6">
                <a className="no-decoration" href="tel:+61290300315">
                  Call Us
                </a>{" "}
                and we can discuss things with you further.
              </p>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

/*
       
<div className="row m-4">
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 d-flex flex-column" />
          <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 d-flex flex-column">
            <table className="table table-hover table-bordered shadow-sm">
              <thead>
                <tr>
                  <th style={{ width: "46%" }} scope="col" />
                  <th className="heading-cell" scope="col">
                    <div className="heading-square">Basic</div>
                  </th>
                  <th className="heading-cell" scope="col">
                    <div className="heading-square">Standard</div>
                  </th>
                  <th className="heading-cell" scope="col">
                    <div className="heading-square">Enterprise</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="table-row">
                    BAS Statements BAS Statements BAS Statements
                  </td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td className="table-row">
                    BAS Statements BAS Statements BAS Statements
                  </td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td className="table-row">
                    BAS Statements BAS Statements BAS Statements
                  </td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td className="table-row">
                    BAS Statements BAS Statements BAS Statements
                  </td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td className="table-row">
                    BAS Statements BAS Statements BAS Statements
                  </td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td className="table-row">
                    BAS Statements BAS Statements BAS Statements
                  </td>
                  <td className="cross">&#10008;</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td className="table-row">
                    BAS Statements BAS Statements BAS Statements
                  </td>
                  <td className="cross">&#10008;</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td className="table-row">
                    BAS Statements BAS Statements BAS Statements
                  </td>
                  <td className="cross">&#10008;</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td className="table-row">
                    BAS Statements BAS Statements BAS Statements
                  </td>
                  <td className="cross">&#10008;</td>
                  <td className="cross">&#10008;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td className="table-row">
                    BAS Statements BAS Statements BAS Statements
                  </td>
                  <td className="cross">&#10008;</td>
                  <td className="cross">&#10008;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td className="table-row">
                    BAS Statements BAS Statements BAS Statements
                  </td>
                  <td className="cross">&#10008;</td>
                  <td className="cross">&#10008;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td className="table-row" />
                  <td>
                    <a
                      className="btn btn-primary btn-lg btn-block"
                      href="/signupone"
                    >
                      Sign Up
                    </a>
                  </td>
                  <td>
                    <a
                      className="btn btn-primary btn-lg btn-block"
                      href="/signupone"
                    >
                      Sign Up
                    </a>
                  </td>
                  <td>
                    <a
                      className="btn btn-primary btn-lg btn-block"
                      href="/signupone"
                    >
                      Sign Up
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 d-flex flex-column" />
        <div className="y-200" />
<div className="y-200" />
        <div className="row y-300">
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 d-flex flex-column" />
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
            <div className="col-stretch cen-mid">
              <h2 className="bg-dark w-75 y-300 cen-mid">placeholder</h2>
            </div>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
            <div className="cen-bot">
              <h2 className="p-4">Basic Plan</h2>
            </div>
            <div className="col-stretch cen-top">
              <ul className="list-unstyled">
                <li>Payroll for 10 or more people</li>
                <li>Processing of hard copy reciepts</li>
                <li>Quarterly BAS statements</li>
                <li>EOFY tax return</li>
                <li>Monthly bank reconciliation</li>
              </ul>
            </div>
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 d-flex flex-column" />
        </div>

        <div className="y-100" />
        <div className="row y-300">
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 d-flex flex-column" />
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
            <div className="cen-bot">
              <h2 className="p-4">Standard Plan</h2>
            </div>
            <div className="col-stretch cen-top">
              <ul className="list-unstyled">
                <li>Payroll for 10 or more people</li>
                <li>Processing of hard copy reciepts</li>
                <li>Quarterly BAS statements</li>
                <li>EOFY tax return</li>
                <li>Monthly bank reconciliation</li>
              </ul>
            </div>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
            <div className="col-stretch cen-mid">
              <h2 className="bg-dark w-75 y-300 cen-mid">placeholder</h2>
            </div>
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 d-flex flex-column" />
        </div>

        <div className="y-100" />
        <div className="row y-300">
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 d-flex flex-column" />
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
            <div className="col-stretch cen-mid">
              <h2 className="bg-dark w-75 y-300 cen-mid">placeholder</h2>
            </div>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
            <div className="cen-bot">
              <h2 className="p-4">Enterprise Plan</h2>
            </div>
            <div className="col-stretch cen-top">
              <ul className="list-unstyled">
                <li>Payroll for 10 or more people</li>
                <li>Processing of hard copy reciepts</li>
                <li>Quarterly BAS statements</li>
                <li>EOFY tax return</li>
                <li>Monthly bank reconciliation</li>
              </ul>
            </div>
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 d-flex flex-column" />
        </div>
        <div className="y-200" />

*/

export default Pricing;
