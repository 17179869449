import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import { deleteTicket } from "../../actions/ticketActions";
import { Link } from "react-router-dom";
import moment from "moment";

const TicketDetails = props => {
  const { ticket, auth, id } = props;
  if (!auth.uid) return <Redirect to="/signin" />;
  if (ticket) {
    if (!ticket.lastUpdated == null) {
      var updateTime = moment(ticket.lastUpdated.toDate()).fromNow();
    } else {
      updateTime = moment(ticket.createdAt.toDate()).fromNow();
    }
    return (
      <div className="container-fluid">
        <div className="y-50" />
        <div className="cen-mid d-flex flex-column">
          <h3 className="text-capitalize p-4">{ticket.title}</h3>
          <div className="y-20" />
          <p className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
            {ticket.content}
          </p>
          <div className="y-50" />
          <div>
            <Link to={"/update/" + id} key={id} className="btn btn-light m-2">
              Update
            </Link>{" "}
            <button
              className="btn btn-light m-2"
              onClick={() => {
                if (window.confirm("Want to delete this?")) {
                  props.deleteTicket(props);
                  return <Redirect to="/support" />;
                }
              }}
            >
              Delete
            </button>
          </div>
          <p className="small p-4">Last updated {updateTime}</p>
        </div>
        <div className="y-100" />
      </div>
    );
  } else return <Redirect to="/support" />;
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const tickets = state.firestore.data.tickets;
  const ticket = tickets ? tickets[id] : null;
  return {
    id: id,
    ticket: ticket,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteTicket: ticket => dispatch(deleteTicket(ticket))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: "tickets" }, { collection: "auditTicket" }])
)(TicketDetails);
