const initState = {
  uploadFileSuccess: null,
  uploadFileError: null,
  hwReceiptsSuccess: null,
  hwReceiptsError: null
};

const uploadReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPLOAD_FILE":
      console.log("Uploaded file.");
      return {
        ...state,
        uploadFileSuccess: "Updated profile.",
        uploadFileError: null
      };

    case "UPLOAD_FILE_ERROR":
      console.log("File upload error.");
      return {
        ...state,
        uploadFileSuccess: null,
        uploadFileError: action.err.message
      };

    case "UPLOAD_RECIEPT":
      console.log("Receipt upload success.");
      return {
        ...state,
        hwReceiptsSuccess: "Uploaded receipt.",
        hwReceiptsError: null
      };

    case "UPLOAD_RECIEPT_ERROR":
      console.log("Receipt upload error.");
      return {
        ...state,
        hwReceiptsSuccess: null,
        hwReceiptsError: action.err.message
      };

    default:
      return state;
  }
};

export default uploadReducer;
