import React, { Component } from "react";
//import moment from "moment";
import { connect } from "react-redux";
import { editTicket } from "../../actions/viewActions";
//import { Redirect } from "react-router-dom";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

class ViewTickets extends Component {
  state = { id: this.props.ticket.id };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
    console.log(this.state);
  };

  handleSubmit = e => {
    e.preventDefault();
    console.log(this.state);
    this.props.editTicket(this.state);
  };

  render() {
    const { ticket } = this.props;
    //const { auth, ticket } = this.props;
    //if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <form
        className="form-horizontal m-4"
        onSubmit={this.handleSubmit}
        to="/view"
      >
        <div className="table-responsive">
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <small>{ticket.userId.slice(0, 4)}</small>
                </td>
                <td>
                  <input
                    type="text"
                    id="title"
                    className="form-control form-control-sm"
                    defaultValue={ticket.title}
                    required
                    onChange={this.handleChange}
                  />
                </td>
                <td>
                  <textarea
                    type="text"
                    id="content"
                    className="form-control form-control-sm"
                    defaultValue={ticket.content}
                    required
                    onChange={this.handleChange}
                  />
                </td>
                <td>
                  <input
                    className="btn btn-sm btn-primary form-control-sm"
                    type="submit"
                    value="Update"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  console.log(state);
  return {
    auth: state.firebase.auth,
    tickets: state.firestore.ordered.tickets
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editTicket: props => dispatch(editTicket(props))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([
    {
      collection: "tickets",
      orderBy: ["lastUpdated", "desc"]
    }
  ])
)(ViewTickets);
