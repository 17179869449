export const contactUs = props => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("contact")
      .add({
        ...props,
        createdAt: new Date()
      })
      .then(() => {
        dispatch({ type: "CONTACT_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "CONTACT_ERROR", err });
      });
  };
};

export const refSignUp = props => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("referrers")
      .add({
        ...props,
        refCount: 0,
        plansBasic: 0,
        plansStandard: 0,
        plansEnterprise: 0,
        refAmount: 0,
        createdAt: new Date()
      })
      .then(() => {
        dispatch({ type: "REFERRER_SIGNUP_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "REFERRER_SIGNUP_ERROR", err });
      });
  };
};
