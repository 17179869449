import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const TicketSummary = ({ ticket }) => {
  return (
    <div className="card shadow-sm m-3 cen-mid col-xs-3 col-sm-3 col-md-3 col-lg-3">
      <div className="card-body">
        <h5 className="card-title text-capitalize">
          {ticket.title.slice(0, 10)}
        </h5>
        <p className="card-text">{ticket.content.slice(0, 40)}</p>
        <Link
          to={"/ticket/" + ticket.id}
          key={ticket.id}
          className="btn btn-light m-2"
        >
          View
        </Link>
        <br />
        <small className="card-text text-muted">
          {moment(ticket.createdAt.toDate()).format("MMM Do YYYY")}
        </small>
      </div>
    </div>
  );
};

export default TicketSummary;
