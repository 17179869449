import React, { Component } from "react";
import StripeCheckout from "react-stripe-checkout";
import { updateCard } from "../../actions/stripeActions";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
//import axios from "axios";
// import { toast } from "react-toastify";
// toast.configure();

class UpdateCard extends Component {
  handleToken = token => {
    this.setState({
      token: token.id
    });
    this.props.updateCard(this.state);
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="form-horizontal m-4">
          <div className="cen-mid">
            <h4 className="">Update Payment</h4>
          </div>
          <div className="cen-mid">
            <small className="">*Changes card on account</small>
          </div>
          <div className="form-group row cen-mid mt-4">
            <StripeCheckout
              style={{
                display: "none"
              }}
              stripeKey="pk_live_OB4Xv6ya4ZpBG8SwGy8X78Ib"
              token={this.handleToken}
              name="Bookkeeping Stop"
              label="Update Payment"
              panelLabel="Add Card"
            >
              <button className="btn btn-md btn-primary">Update Payment</button>
            </StripeCheckout>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const users = state.firestore.ordered.users;
  const user = users ? users[0] : null;
  return {
    auth: state.firebase.auth,
    user: user,
    uid: state.firebase.auth.uid
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateCard: props => dispatch(updateCard(props))
  };
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect(props => {
    if (!props.auth.uid) return [];
    return [
      {
        collection: "users",
        doc: props.auth.uid
      }
    ];
  })
)(UpdateCard);
