import React, { Component } from "react";
import { connect } from "react-redux";
import { hwReceipts } from "../../actions/uploadActions";

class Hairhouse extends Component {
  state = {
    fileName: "Take photo of reciept",
    date: new Date().toISOString().substr(0, 10),
    employeeName: "",
    company: "",
    reason: "",
    totalAmount: ""
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
    console.log(this.state);
  };

  handleFile = e => {
    this.setState({
      file: e.target.files[0]
    });
    console.log(this.state);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.hwReceipts(this.state);
  };

  render() {
    const { hwReceiptsError, hwReceiptsSuccess } = this.props;

    return (
      <div className="container-fluid">
        <div className="y-50" />
        <form className="form-horizontal m-4" onSubmit={this.handleSubmit}>
          <div className="y-100 cen-mid">
            <h4 className="">HW Petty Cash</h4>
          </div>
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="date"
            >
              Date:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="date"
                id="date"
                className="form-control"
                defaultValue={new Date().toISOString().substr(0, 10)}
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="employeeName"
            >
              Employee name:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="text"
                id="employeeName"
                placeholder="Enter employee name"
                className="form-control"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="company"
            >
              Company:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="text"
                id="company"
                placeholder="Enter company purchased from"
                className="form-control"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="reason"
            >
              Reason:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="text"
                id="reason"
                placeholder="Reason for needing petty cash"
                className="form-control"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="totalAmount"
            >
              Total Amount:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="number"
                min="0.00"
                max="10000.00"
                step="0.01"
                id="totalAmount"
                className="form-control"
                defaultValue="0.00"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="text"
            />
            <div className="col-sm-8 col-md-6 col-lg-4">
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="reciept"
                  onChange={this.handleFile}
                />
                <label className="custom-file-label text-left text-muted">
                  {this.state.file ? this.state.file.name : this.state.fileName}
                </label>
              </div>
            </div>
          </div>
          <br />
          <div className="cen-mid">
            {hwReceiptsError ? (
              <div className="text-danger pb-4">
                <p>{hwReceiptsError}</p>
              </div>
            ) : null}
          </div>
          <div className="pb-4 cen-mid">
            {hwReceiptsSuccess ? (
              <div className="text-primary">
                <p>{hwReceiptsSuccess}</p>
              </div>
            ) : null}
          </div>
          <br />
          <div className="form-group row cen-mid">
            <input
              className="btn btn-md btn-primary"
              type="submit"
              value="Submit"
            />
          </div>
        </form>
        <div className="y-100" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    hwReceiptsError: state.upload.hwReceiptsError,
    hwReceiptsSuccess: state.upload.hwReceiptsSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hwReceipts: props => dispatch(hwReceipts(props))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Hairhouse);
