import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import Profile from "./Profile";
import ChangePlan from "./ChangePlan";
import ChangePassword from "./ChangePassword";
import UpdateCard from "../stripe/UpdateCard";

class Account extends Component {
  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to="/home" />;
    else
      return (
        <div className="container-fluid">
          <div className="y-50" />
          <Profile />
          <div className="y-50" />
          <ChangePlan />
          <div className="y-50" />
          <UpdateCard />
          <div className="y-50" />
          <ChangePassword />
          <div className="y-100 d-none d-md-block" />
          <div className="y-50 d-md-none" />
        </div>
      );
  }
}

/*
<PlanDetails />
          <div className="y-50" />
          <PaymentDetails auth={auth} payment={payment} />
          <div className="y-50" />
          */

const mapStateToProps = state => {
  return {
    payment: state.firestore.ordered.payment,
    auth: state.firebase.auth
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: "payment",
      orderBy: ["lastUpdated", "desc"]
    }
  ])
)(Account);
