import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { signUp } from "../../actions/authActions";

class SignUpOne extends Component {
  state = {
    firstName: "",
    lastName: "",
    businessName: "",
    employees: "",
    phoneNumber: "",
    email: "",
    password: ""
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.signUp(this.state);
  };

  render() {
    const { auth, authError } = this.props;
    if (auth.uid) return <Redirect to="/dashboard" />;

    return (
      <div className="container-fluid">
        <form
          className="form-signin col-xs-8 col-sm-8 col-md-8 col-lg-8 mx-auto"
          onSubmit={this.handleSubmit}
          to="/clientHome"
        >
          <div className="y-50" />
          <div className="align-self-left p-2">
            <p className="">
              Step <strong>3</strong> of <strong>3</strong>
            </p>
          </div>
          <br />
          <div className="cen-mid p-2">
            <h1 className="">Choose your payment method</h1>
          </div>
          <br />
          <div
            className="alert-danger rounded col-xs-6 col-sm-6 col-md-6 col-lg-6 mx-auto cen-mid"
            style={{ color: "#ff0000" }}
          >
            {authError ? <p>{authError}</p> : null}
          </div>
          <div className="y-50" />

          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 d-flex flex-column mx-auto">
            <div className="form-label-group">
              <input
                type="text"
                id="cardName"
                className="form-control"
                placeholder="Name on card"
                required
                onChange={this.handleChange}
              />
            </div>
            <br />
            <div className="form-label-group">
              <input
                type="number"
                id="cardNumber"
                className="form-control"
                placeholder="Card number"
                required
                onChange={this.handleChange}
              />
            </div>
            <br />
            <div className="form-label-group">
              <input
                type="month"
                id="cardExpiry"
                className="form-control"
                placeholder="MM/YY"
                required
                onChange={this.handleChange}
              />
            </div>
            <br />
            <div className="form-label-group">
              <input
                type="number"
                id="cardCCV"
                className="form-control"
                placeholder="***"
                required
                onChange={this.handleChange}
              />
            </div>
            <br />
            <div className="checkbox cen-mid">
              <label>
                <input type="checkbox" value="agree-terms" required />
                <small className="p-2">
                  I have read and agree to the{" "}
                  <a href="/terms">terms and conditions</a> and{" "}
                  <a href="/privacy">privacy policy</a>.
                </small>
              </label>
            </div>
            <br />
            <div className="">
              <button
                className="btn btn-lg btn-primary btn-block"
                type="submit"
              >
                Sign Up
              </button>
            </div>
          </div>
        </form>
        <div className="y-100" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signUp: newUser => dispatch(signUp(newUser))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpOne);
