const initState = {
  refSignUpSuccess: null,
  refSignUpError: null
};

const contactReducer = (state = initState, action) => {
  switch (action.type) {
    case "CONTACT_SUCCESS":
      console.log("Contact form success");
      return {
        ...state,
        contactUsSuccess: "Thanks for the message. We will be in contact soon!",
        contactUsError: null
      };
    case "CONTACT_ERROR":
      console.log(
        "There was an error when submitting the contact form. Please try again."
      );
      return {
        ...state,
        contactUsSuccess: null,
        contactUsError: action.err.message
      };
    case "CONTACT_CAREER_SUCCESS":
      console.log("Contact form success");
      return {
        ...state,
        contactCareerSuccess:
          "Thanks for the message. We will be in contact soon!",
        contactCareerError: null
      };
    case "CONTACT_CAREER_ERROR":
      console.log(
        "There was an error when submitting the contact form. Please try again."
      );
      return {
        ...state,
        contactCareerSuccess: null,
        contactCareerError: action.err.message
      };
    case "REFERRER_SIGNUP_SUCCESS":
      console.log("Referrer signup success");
      return {
        ...state,
        refSignUpSuccess:
          "Congratulations! You have successful applied to be a part of our referral program.",
        refSignUpError: null
      };
    case "REFERRER_SIGNUP_ERROR":
      console.log(
        "There was an error when processing your application. Please try again."
      );
      return {
        ...state,
        refSignUpSuccess: null,
        refSignUpError: action.err.message
      };
    default:
      return state;
  }
};

export default contactReducer;
