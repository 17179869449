import React, { Component } from "react";
//import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import ContactForm from "./ContactForm";

class Contact extends Component {
  render() {
    /*
    const Map = withGoogleMap(props => (
      <GoogleMap
        mapTypeControlOptions={
          window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR
        }
        controlPosition={window.google.maps.ControlPosition.TOP_RIGHT}
        defaultCenter={{ lat: -33.86926, lng: 151.20872 }}
        defaultZoom={15}
      >
        <Marker position={{ lat: -33.86926, lng: 151.20872 }} />
      </GoogleMap>
    ));
      */

    return (
      <div className="container-fluid">
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
        <div className="d-none d-md-flex flex-column">
          <div className="mx-auto">
            <h4>Main Office</h4>
          </div>
          <div className="mx-auto">
            <p>(Sydney, Australia)</p>
          </div>
        </div>

        <div className="row y-400 d-none d-md-flex mx-auto">
          <div className="col m-4 text-right">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d828.1983794248399!2d151.20827082918987!3d-33.86921188525565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12af3d5003f3c7%3A0x444ada72c865c056!2sBookkeeping+Stop!5e0!3m2!1sen!2sau!4v1549087631736"
              width="80%"
              height="100%"
              frameBorder="0"
              zoom="1"
              style={{ border: "0" }}
              allowFullScreen
            />
            {/*<Map
                containerElement={<div className="map-container" />}
                mapElement={<div className="map" />}
              />
              */}
          </div>
          <div className="col flex-column m-4">
            <br />
            <br />
            <div>
              <text className="font-5">Street address:</text>
            </div>
            <div>
              <a href="https://goo.gl/maps/kwH8TaT2puy" className="text-dark">
                Level 13, 135 King Street
                <br /> Sydney, NSW, 2000
              </a>
            </div>
            <br />
            <div>
              <text className="font-5">Phone numbers:</text>
            </div>
            <div>
              <text>Domestic: </text>
              <a href="tel:+61290300315" className="text-dark">
                02 9030 0315
              </a>
            </div>
            <div>
              <text className="font-5">International: </text>
              <a href="tel:+61290300315" className="text-dark">
                +612 9030 0315
              </a>
            </div>
            <br />
            <div>
              <text className="font-5">Email address: </text>
            </div>
            <div>
              <a href="mailto:info@bookkeepingstop.com" className="text-dark">
                info@bookkeepingstop.com
              </a>
            </div>
          </div>
        </div>

        <div className="d-md-none flex-column">
          <div className="row flex-column text-center">
            <div className="mx-auto">
              <p className="font-6 h5">Main office</p>
            </div>
            <div className="mx-auto">
              <p>(Sydney, Australia)</p>
            </div>
          </div>
          <br />

          <div className="row flex-column m-2 text-left">
            <div className="mx-auto">
              <text className="font-5">Street address:</text>
              <br />
              <a href="https://goo.gl/maps/kwH8TaT2puy">
                Level 13, 135 King Street
                <br /> Sydney, NSW, 2000
              </a>
              <br />
              <br />
              <text className="font-5">Phone numbers:</text>
              <br />
              <text className="font-5">Domestic: </text>
              <a href="tel:+61290300315">02 9030 0315</a>
              <br />
              <text className="font-5">International: </text>
              <a href="tel:+61290300315">+612 9030 0315</a>
              <br />
              <br />
              <text className="font-5">Email address: </text>
              <br />
              <a href="mailto:info@bookkeepingstop.com">
                info@bookkeepingstop.com
              </a>
            </div>
          </div>
          <br />

          <div className="row m-2">
            <div className="mx-auto">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d828.1983794248399!2d151.20827082918987!3d-33.86921188525565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12af3d5003f3c7%3A0x444ada72c865c056!2sBookkeeping+Stop!5e0!3m2!1sen!2sau!4v1549087631736"
                width="350px"
                height="350px"
                frameBorder="0"
                zoom="1"
                style={{ border: "0" }}
                allowFullScreen
              />
              {/*<Map
                containerElement={<div className="map-container" />}
                mapElement={<div className="map" />}
              />
              */}
            </div>
          </div>
        </div>

        <div className="y-50" />
        <div className="y-50" />
        <div className="y-100 cen-mid">
          <h4 className="">Message Us</h4>
        </div>
        <ContactForm />
        <div className="y-50" />

        <div className="row d-none d-md-flex flex-column mx-auto">
          <div className="col-stretch cen-mid">
            <h4 className="text-muted">
              Want to have a chat?{" "}
              <a className="no-decoration" href="tel:+61290300315">
                Call us
              </a>{" "}
              or
              <a
                className="no-decoration"
                href="mailto:info@bookkeepingstop.com"
              >
                {" "}
                email us
              </a>
            </h4>
          </div>
          <br />
          <div className="col-stretch cen-mid">
            <h4 className="p-2">We love meeting new people!</h4>
          </div>
        </div>

        <div className="row d-md-none">
          <div className="d-flex flex-column mx-auto">
            <div className="col-stretch cen-mid">
              <p className="p-2 font-6 text-muted">
                Want to have a chat?{" "}
                <a className="no-decoration" href="tel:+61290300315">
                  Call us
                </a>{" "}
                or
                <a
                  className="no-decoration"
                  href="mailto:info@bookkeepingstop.com"
                >
                  {" "}
                  email us
                </a>
              </p>
            </div>
            <div className="col-stretch cen-mid">
              <p className="p-2 font-6">We love meeting new people!</p>
            </div>
          </div>
        </div>
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
      </div>
    );
  }
}
export default Contact;
