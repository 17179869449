import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import ActivityThisMonth from "./ActivityThisMonth";
import ActivityLastMonth from "./ActivityLastMonth";
import ActivitySixMonths from "./ActivitySixMonths";
import ActivityYTD from "./ActivityYTD";
import ActivityAll from "./ActivityAll";

class Activity extends Component {
  constructor() {
    super();
    this.state = {
      show: false
    };
    console.log(this.state);
  }

  thisMonth = e => {
    this.setState({
      show:
        this.props.entries &&
        this.props.entries.map(entry => {
          return <ActivityThisMonth key={entry.id} entry={entry} />;
        })
    });
    document.getElementById("thisMonth").className = "btn btn-light active";
    document.getElementById("lastMonth").className = "btn btn-light";
    document.getElementById("sixMonths").className = "btn btn-light";
    document.getElementById("ytd").className = "btn btn-light";
    document.getElementById("all").className = "btn btn-light";
  };

  lastMonth = e => {
    this.setState({
      show:
        this.props.entries &&
        this.props.entries.map(entry => {
          return <ActivityLastMonth key={entry.id} entry={entry} />;
        })
    });
    document.getElementById("thisMonth").className = "btn btn-light";
    document.getElementById("lastMonth").className = "btn btn-light active";
    document.getElementById("sixMonths").className = "btn btn-light";
    document.getElementById("ytd").className = "btn btn-light";
    document.getElementById("all").className = "btn btn-light";
  };

  sixMonths = e => {
    this.setState({
      show:
        this.props.entries &&
        this.props.entries.map(entry => {
          return <ActivitySixMonths key={entry.id} entry={entry} />;
        })
    });
    document.getElementById("thisMonth").className = "btn btn-light";
    document.getElementById("lastMonth").className = "btn btn-light";
    document.getElementById("sixMonths").className = "btn btn-light active";
    document.getElementById("ytd").className = "btn btn-light";
    document.getElementById("all").className = "btn btn-light";
  };

  ytd = e => {
    this.setState({
      show:
        this.props.entries &&
        this.props.entries.map(entry => {
          return <ActivityYTD key={entry.id} entry={entry} />;
        })
    });
    document.getElementById("thisMonth").className = "btn btn-light";
    document.getElementById("lastMonth").className = "btn btn-light";
    document.getElementById("sixMonths").className = "btn btn-light";
    document.getElementById("ytd").className = "btn btn-light active";
    document.getElementById("all").className = "btn btn-light";
  };

  all = e => {
    this.setState({
      show:
        this.props.entries &&
        this.props.entries.map(entry => {
          return <ActivityAll key={entry.id} entry={entry} />;
        })
    });
    document.getElementById("thisMonth").className = "btn btn-light";
    document.getElementById("lastMonth").className = "btn btn-light";
    document.getElementById("sixMonths").className = "btn btn-light";
    document.getElementById("ytd").className = "btn btn-light";
    document.getElementById("all").className = "btn btn-light active";
  };

  render() {
    const { auth } = this.props;
    console.log(this.props);
    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div className="container-fluid">
        <div className="y-50" />
        <div className="y-100 cen-mid">
          <h4 className="text-center">Transactions</h4>
        </div>
        <div className="cen-mid" id="myBtnContainer">
          <button
            className="btn btn-light active"
            id="thisMonth"
            onClick={this.thisMonth}
          >
            This Month
          </button>
          <button
            className="btn btn-light"
            id="lastMonth"
            onClick={this.lastMonth}
          >
            Last Month
          </button>
          <button className="btn-light" id="sixMonths" onClick={this.sixMonths}>
            Last 6 Months
          </button>
          <button className="btn-light" id="ytd" onClick={this.ytd}>
            This Financial Year
          </button>
          <button className="btn-light" id="all" onClick={this.all}>
            All
          </button>
        </div>
        <br />
        <div>
          {this.state.show
            ? this.state.show
            : this.props.entries &&
              this.props.entries.map(entry => {
                return <ActivityThisMonth key={entry.id} entry={entry} />;
              })}
        </div>
        <br />
        <div className="y-200" />
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log(state);
  return {
    auth: state.firebase.auth,
    entries: state.firestore.ordered.ledgerDraft
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    if (!props.auth.uid) return [];
    return [
      {
        collection: "ledgerDraft",
        where: ["id", "==", props.auth.uid]
      }
    ];
  })
)(Activity);
