import React, { Component } from "react";
import ContactForm from "./ContactForm";

class Careers extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="y-100" />

        <div className="row d-flex flex-column">
          <div className="mx-auto">
            <h4 className="">Current Vacancies</h4>
          </div>
          <br />
          <br />
          <div className="mx-auto">
            <p className="text-center">
              We are always on the lookout for new staff.
            </p>
            <p className="">
              <a className="no-decoration" href="tel:+61290300315">
                Call Us
              </a>{" "}
              or{" "}
              <a
                className="no-decoration"
                href="mailto:info@bookkeepingstop.com"
              >
                {" "}
                email us
              </a>{" "}
              if you want to be part of the team.
            </p>
          </div>
        </div>

        <div className="y-100" />
        <div className="y-100 cen-mid">
          <h4 className="">Contact Us</h4>
        </div>
        <ContactForm />
        <div className="y-50" />
      </div>
    );
  }
}

export default Careers;
