import React, { Component } from "react";

class Privacy extends Component {
  render() {
    return (
      <div className="container">
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />

        <div className="row d-flex flex-column">
          <div className="mx-auto">
            <h4 className="">Privacy Policy</h4>
          </div>
        </div>

        <div className="row">
          <div className="p-4">
            <p>1 INTRODUCTION</p>

            <p className="">1.1</p>
            <p className="">
              This Privacy Policy was last updated on 6 July 2016.
            </p>

            <p className="">1.2</p>
            <p className="">
              Bookkeeping Stop Pty Ltd (ACN 624 703 055) (Bookkeeping Stop, we,
              us and our) is committed to maintaining the confidentiality,
              integrity and security of the Personal Information that we
              collect, hold, use and disclose. This Privacy Policy explains how
              we manage Personal Information and other information about You.
            </p>

            <p className="">1.3</p>
            <p className="">For the purposes of this Privacy Policy:</p>
            <ul>
              <li>
                "Account Credentials" means the combination of access code,
                access number, client number, email address, password, secondary
                password and/or security number (as applicable) which You use to
                access the online banking services offered by Your bank or other
                financial institution in respect of Your Bank Account
              </li>
              <li>
                "Account Information" means day-to-day transactional information
                (including all debit and credit operations) in respect of Your
                Bank Account
              </li>
              <li>
                "Bank Account" means a deposit or loan account that You maintain
                with an Australian bank or another Australian financial
                institution
              </li>
              <li>
                "Mobile App" means the Bookkeeping Stop Mobile Device
                application
              </li>
              <li>
                "Mobile Device" means a mobile phone, tablet or other
                internet-enabled device that is compatible with the Service and
                has, or has access to, an internet connection
              </li>
              <li>
                "Personal Information" means information about an identified
                individual or an individual who is reasonably identifiable.
                Common examples are an individual’s name, signature, address,
                telephone number, date of birth, email address, bank account
                details and employment details
              </li>
              <li>
                "Bookkeeping Stop Registration Information" means Your email
                address and Your password for Your Bookkeeping Stop account
              </li>
              <li>
                "Service" means the services provided by the Web App and/or the
                Mobile App
              </li>
              <li>
                "Web App" means the Bookkeeping Stop web application which may
                be accessed at{" "}
                <a href="https://getbookkeepingstop.com/">
                  https://getbookkeepingstop.com/
                </a>
              </li>
              <li>
                "You" or "Your" means the individual who uses the Web App and/or
                the Mobile App and
              </li>
              <li>
                the words "includes", "including", “for example ”, "such as" and
                similar terms or expressions are not words of limitation.
              </li>
            </ul>

            <p className="">1.4</p>
            <p className="">
              Our privacy and security procedures aim to (1) prevent identity
              theft and (2) secure the Personal Information that we hold. We
              regularly re-evaluate those procedures and adapt them, as
              necessary, to deal with new challenges and emerging issues. We
              strive to implement best-practice security standards.
            </p>

            <p className="">
              <p>2 THE KINDS OF INFORMATION THAT WE COLLECT AND HOLD</p>
            </p>

            <p className="">2.1</p>
            <p className="">
              Certain features of the Service are made available to You without
              the need to register or to provide any of Your Personal
              Information to us. However, other features of the Service require
              You to register as a Bookkeeping Stop account holder.
            </p>

            <p className="">
              <p>Registration</p>
            </p>

            <p className="">2.2</p>
            <p className="">
              When You register as a Bookkeeping Stop account holder, we will
              collect Personal Information such as Your email address, Your
              first name and Your last name (depending on whether you register
              via the Mobile App or the Web App).
            </p>

            <p className="">
              <p>Account Credentials</p>
            </p>

            <p className="">2.3</p>
            <p className="">
              In order to benefit from the full functionality of the Service,
              You must provide us with Your Account Credentials. By doing so,
              You will enable us to link Your Bookkeeping Stop account with Your
              Bank Account. For clarity, Account Information will be collected
              from all Bank Accounts for which You provide Account Credentials.
            </p>

            <p className="">
              <p>Other Information</p>
            </p>

            <p className="">2.4</p>
            <p className="">
              Due to the nature of the Service, from time to time, we may
              collect and hold additional Personal Information or other
              information about You. This can include:
            </p>
            <ul>
              <li>Your address, date of birth and contact details</li>
              <li>
                information about Your financial circumstances and objectives,
                including Your assets, liabilities, income, expenditure,
                taxation information, insurance, superannuation and investment
                preferences
              </li>
              <li>
                the type of operating system and/or other software or firmware
                used by Your computer or Mobile Device
              </li>
              <li>
                the data You send and receive by using the Service, as well as
                the type and quality of that data
              </li>
              <li>your GPS location</li>
              <li>
                the dates on which and the times at which You use the Service,
                including the duration of such use and
              </li>
              <li>
                the IP address and the MAC address of Your computer or Mobile
                Device.
              </li>
            </ul>
            <p>
              This is not an exhaustive list. For example, in certain cases we
              may also collect Your tax file number.
            </p>

            <p className="">
              <p>Tax File Number</p>
            </p>

            <p className="">2.5</p>
            <p className="">
              We will only collect, hold, use and disclose Your tax file number
              in accordance with the <em>Privacy Act 1988 </em>(Cth), the{" "}
              <em>Privacy (Tax File Number) Rule 2015</em>, the{" "}
              <em>Tax Administration Act 1953 </em>(Cth) and other applicable
              laws (as amended from time to time). Without limiting our
              obligations under those laws, we will take reasonable steps to
              ensure that You are informed of:
            </p>
            <ul>
              <li>
                the taxation law or other applicable law which authorises us to
                request or collect Your tax file number
              </li>
              <li>
                the purpose(s) for which Your tax file number is requested and
                collected and
              </li>
              <li>
                the consequences of declining to quote Your tax file number.
              </li>
            </ul>

            <p>3 HOW WE COLLECT INFORMATION?</p>

            <p className="">3.1</p>
            <p className="">
              We may collect Your information in a number of ways including:
            </p>
            <ul>
              <li>
                directly from You, such as:
                <ul>
                  <li>
                    when You provide information to us when You register to use
                    the Service
                  </li>
                  <li>when You use the Service</li>
                  <li>
                    when You participate in contests, giveaways and other
                    promotions via the Wed App or the Mobile App
                  </li>
                  <li>when You participate in one of our surveys or</li>
                  <li>when You contact us with an enquiry on a request</li>
                </ul>
              </li>
              <li>
                through the Service, when You synchronise Your Bookkeeping Stop
                account with Your Bank Account
              </li>
              <li>
                from third-parties You authorise to disclose Your information to
                us
              </li>
              <li>from publicly available sources of information</li>
              <li>when we are legally authorised or required to do so or</li>
              <li>
                through digital communications, such as when You visit our
                website.
              </li>
            </ul>

            <p className="">3.2</p>
            <p className="">
              You have a right not to provide information to us. We will give
              You the opportunity to provide or to decline to provide
              information and, where practicable and lawful, we will allow You
              to interact with us anonymously or by using a pseudonym. However,
              if You choose not to provide certain information about You, we may
              not be able to provide You with the service You request or the
              level of service on which we pride ourselves.
            </p>

            <p className="">
              <p>4 HOW DO WE HOLD YOUR INFORMATION?</p>
            </p>

            <p className="">4.1</p>
            <p className="">
              We take the privacy and security of Your information seriously.
            </p>

            <p className="">4.2</p>
            <p className="">
              We use various technical solutions, security controls and internal
              procedures to help us protect Your information. For example:
            </p>
            <ul>
              <li>
                we use a combination of firewall barriers, encryption techniques
                and authentication procedures:
                <ul>
                  <li>to maintain the security of Your online session and</li>
                  <li>
                    to protect Your information, the Bookkeeping Stop accounts
                    and our systems from misuse, interference and loss, and from
                    unauthorised access, modification or disclosure
                  </li>
                </ul>
              </li>
              <li>
                when You register as a Bookkeeping Stop account hold, we require
                a password from You for Your privacy and security
              </li>
              <li>
                we transmit information such as Your Registration Information or
                Your Account Credentials only securely
              </li>
              <li>
                from the time You log in to use the Service, the communications
                between Your computer or Mobile Device and the Web App or Mobile
                App are encrypted. This enables client and server applications
                to communicate in a way that is designed to prevent
                eavesdropping, tampering and message forgery and
              </li>
              <li>
                we test the Web App and the Mobile App regularly for any failure
                points that would allow hacking.
              </li>
            </ul>

            <p className="">4.3</p>
            <p className="">
              For clarity, these precautions apply only to the Service. We
              exercise no control over how Your information is stored,
              maintained or displayed by third-parties or on third-party
              websites.
            </p>

            <p className="">
              <p>About Your password</p>
            </p>

            <p className="">4.4</p>
            <p className="">
              You are responsible for maintaining the confidentiality of Your
              Bookkeeping Stop Registration Information.
            </p>

            <p className="">4.5</p>
            <p className="">
              We maintain strict rules to help prevent others from guessing Your
              password. We also recommend that You change Your password
              periodically. Your password must be at least 8 characters in
              length. You must not provide Your Bookkeeping Stop Registration
              Information to any third-party. If You believe that Your
              Bookkeeping Stop Registration Information may have been stolen or
              been made known to others, You must contact us immediately at{" "}
              <a href="mailto:info@bookkeepingstop.com">
                info@bookkeepingstop.com
              </a>
              . In any event, You should change Your password immediately via
              the Web App or the Mobile App. We are not responsible if someone
              accesses Your Bookkeeping Stop account if they have obtained Your
              Bookkeeping Stop Registration Information from You or as a result
              of Your breach of this Privacy Policy or the Bookkeeping Stop
              Terms of Use.
            </p>

            <p className="">4.6</p>
            <p className="">
              If You have a security related concern, please contact us at{" "}
              <a href="mailto:info@bookkeepingstop.com">
                info@bookkeepingstop.com
              </a>
              . We will work closely with You to ensure a rapid and personal
              response to Your concerns.
            </p>

            <p className="">
              <p>5 HOW DO WE USE YOUR INFORMATION?</p>
            </p>

            <p className="">5.1</p>
            <p className="">
              Our main purpose for collecting information about You is:
            </p>
            <ul>
              <li>to provide the Service to You</li>
              <li>to provide You with information about the Service</li>
              <li>to assist You with enquires and</li>
              <li>to better service Your needs.</li>
            </ul>

            <p className="">5.2</p>
            <p className="">
              In order to do that effectively, we may use Your information for a
              range of different purposes including:
            </p>
            <ul>
              <li>
                for our business operations and for purposes that are related to
                one or more of our functions and activities
              </li>
              <li>
                to verify Your identity and to allow You to access and use the
                Web App and/or the Mobile App
              </li>
              <li>
                to communicate with You (such as by providing weekly summaries
                of Your Account Information and by sending You email alerts and
                Bookkeeping Stop newsletters)
              </li>
              <li>to provide support and Service updates</li>
              <li>
                to gain an understanding of Your needs so that we may improve
                and develop the Service
              </li>
              <li>
                to personalise Your experience with the Service and our website
              </li>
              <li>
                to perform research and analysis (including to conduct surveys)
              </li>
              <li>
                to comply with our legal, statutory and regulatory obligations
              </li>
              <li>
                for direct marketing purposes (in this respect, see paragraph 3
                below) and
              </li>
              <li>
                subject to Your consent, for other purposes which may be
                notified to You from time to time.
              </li>
            </ul>

            <p className="">
              <p>Direct Marketing</p>
            </p>

            <p className="">5.3</p>
            <p className="">
              We may use Your information so that we and our business partners
              can promote and market products and/or services that we think may
              be of interest to You (including products and/or services provided
              by a third-party). This marketing may be carried out in a variety
              of ways (including by email or by customising online content) and
              may continue after You stop using the Service, until You opt-out.
              You may opt-out by emailing us at{" "}
              <a href="mailto:info@bookkeepingstop.com">
                info@bookkeepingstop.com
              </a>{" "}
              or by following the “unsubscribe” instructions in any email
              communication You receive from us.
            </p>

            <p className="">
              <p>6 HOW DO WE DISCLOSE YOUR INFORMATION?</p>
            </p>

            <p className="">6.1</p>
            <p className="">
              From time to time, we may disclose Your information with entities
              outside of our organisation. These may include:
            </p>
            <ul>
              <li>
                our related bodies corporate, business partners, associates and
                affiliates
              </li>
              <li>
                third-parties who provide services to us, such as vendors or
                service providers that help us provide the Service to You
                (including by sending email messages on our behalf or by hosting
                and operating particular features or functionalities of the
                Service) and
              </li>
              <li>
                anyone who is authorised by law to obtain information about You
                from us.
              </li>
            </ul>

            <p className="">6.2</p>
            <p className="">
              The entities to which we disclose Your information have been
              selected in accordance with our security policies and practices,
              and are bound by confidentiality obligations. If they fail to meet
              these obligations, they may be subject to disciplinary action,
              termination of contract and/or criminal prosecution.
            </p>

            <p className="">6.3</p>
            <p className="">
              Our contracts with the entities to which we disclose Your
              information:
            </p>
            <ul>
              <li>
                outline the appropriate use and handling of Your information and
              </li>
              <li>
                prohibit them from using any of Your information for purposes
                other than those for which they have received Your information.
              </li>
            </ul>

            <p className="">
              <p>Overseas disclosure</p>
            </p>

            <p className="">6.4</p>
            <p className="">
              We do not share information with entities which are located, or
              have operations, outside of Australia. However, should this change
              in the future, we will seek Your consent and we will deal with
              that change in accordance with all applicable laws.
            </p>

            <p className="">
              <p>De-identified information</p>
            </p>

            <p className="">6.5</p>
            <p className="">
              We may de-identify Your Personal Information or aggregate it in
              such a way that it cannot be used to identify You. We may disclose
              de-identified information to several entities, including:
            </p>
            <ul>
              <li>
                advertisers and other third-parties for their commercial,
                marketing, research and promotional purposes
              </li>
              <li>
                organisations approved by us that conduct research into consumer
                spending and
              </li>
              <li>
                other users of the Service for the purpose of comparing their
                personal financial situation against that of the broader
                community.
              </li>
            </ul>

            <p className="">6.6</p>
            <p className="">Our de-identification procedure involves:</p>
            <ul>
              <li>removing personal identifiers</li>
              <li>
                removing or altering other information that may allow You to be
                identified and
              </li>
              <li>
                continuously assessing and managing the risk of
                re-identification.
              </li>
            </ul>

            <p className="">
              <p>7 HOW LONG DO WE KEEP YOUR INFORMATION?</p>
            </p>

            <p className="">7.1</p>
            <p className="">
              Your data is Yours. You can remove it anytime You want. When You
              request us to delete Your Bookkeeping Stop account, Your data will
              be permanently expunged from our primary production servers and
              further access to Your Bookkeeping Stop account will not be
              possible. We will also promptly disconnect any connection we had
              established to Your Account Information and delete all Account
              Credentials.
            </p>

            <p className="">7.2</p>
            <p className="">
              However, portions of Your data, consisting of aggregate data
              derived from Your Account Information, may remain on our
              production servers indefinitely. Your data may also remain on a
              backup server or media. We keep these backups to ensure our
              continued ability to provide the Service to You in the event of
              malfunction or damage to our primary production servers.
            </p>

            <p className="">
              <p>8 HOW TO ACCESS, UPDATE OR CORRECT YOUR INFORMATION?</p>
            </p>

            <p className="">8.1</p>
            <p className="">
              If Your Personal Information changes, You may update it at any
              time via the Web App or the Mobile App. Otherwise please contact
              us at{" "}
              <a href="mailto:info@bookkeepingstop.com">
                info@bookkeepingstop.com
              </a>
              .
            </p>

            <p className="">8.2</p>
            <p className="">
              If You wish to access any of Your information that we hold or You
              would like to correct any errors in that information, You may
              contact us at{" "}
              <a href="mailto:info@bookkeepingstop.com">
                info@bookkeepingstop.com
              </a>
              .
            </p>

            <p className="">8.3</p>
            <p className="">
              The Australian Privacy Principles set out a number of rules in
              respect of accessing, updating and correcting Your information.
              For example, we may refuse to give You access to Your information
              if giving access would have an unreasonable impact on the privacy
              of other individuals, if Your request for access is frivolous or
              vexatious, or if the information relates to existing or
              anticipated legal proceedings.
            </p>

            <p className="">8.4</p>
            <p className="">
              If we refuse to give You access to, or update, or correct Your
              Personal Information, we will provide You with a written
              explanation including the reasons for our refusal.
            </p>

            <p className="">8.5</p>
            <p className="">
              We may charge You an administrative fee for providing access to
              Your information in response to a request.
            </p>

            <p className="">
              <p>9 PRIVACY AND THE INTERNET</p>
            </p>

            <p className="">
              <p>Cookies, web and mobile analytics</p>
            </p>

            <p className="">9.1</p>
            <p className="">
              We may gather information about You through cookie technology. For
              example, we may assign a cookie to You to limit the amount of
              times You see a particular Bookkeeping Stop offer or to help
              better determine which Bookkeeping Stop offers to serve to You.
              Please note that most Internet browsers will allow You to stop
              cookies from being stored on Your device and to delete cookies
              stored on Your device. However, if You choose to eliminate
              cookies, the full functionality of the Service may be impaired for
              You.
            </p>

            <p className="">9.2</p>
            <p className="">
              We encode our cookies so that only we can interpret the
              information stored in them.
            </p>

            <p className="">9.3</p>
            <p className="">
              We also use web and mobile analytics, including using Google
              Advertising ID. For example, Web beacons are images embedded in a
              web page or email for the purpose of measuring and analysing a
              website’s usage and activity. We, or third-party service providers
              acting on our behalf, may use Web beacons to help us analyse
              Service usage and improve the Service.
            </p>

            <p className="">9.4</p>
            <p className="">
              We may use third-party service providers to help us analyse
              certain online activities. For example, these service providers
              may help us measure the performance of our online campaigns or
              analyse visitor activity on the Web App or the Mobile App. We may
              permit these service providers to use cookies and other
              technologies to perform these services for us. We do not share any
              Personal Information about our customers with these third-party
              service providers, and these service providers do not collect such
              information on our behalf.
            </p>

            <p className="">9.5</p>
            <p className="">
              For clarity, the information provided by web beacons and cookies
              may personally identify You. By using the Service, You consent to
              the collection of Your information by those methods.
            </p>

            <p className="">
              <p>Third parties offerings</p>
            </p>

            <p className="">9.6</p>
            <p className="">
              There are a number of products and services offered by
              third-parties, advertised through the Service, that may be
              complementary to Your use of the Service. If You choose to use
              these separate products or services, disclose information to the
              providers, or grant them permission to collect information about
              You, then their use of Your information will governed by their own
              privacy policies.
            </p>

            <p className="">9.7</p>
            <p className="">
              If You click on a link to a third-party website, we encourage You
              to check the privacy policy of that website.
            </p>

            <p className="">9.8</p>
            <p className="">
              We may present links in a format that enables us to keep track of
              whether these links have been followed and whether any action has
              been taken on a third-party website. We use this information to
              improve the quality of our offer and to customise the content
              available from the Service.
            </p>

            <p className="">
              <p>10 YOUR DATA MAY BE TRANSFERRED UPON CHANGE OF CONTROL</p>
            </p>

            <p className="">10.1</p>
            <p className="">
              Personal Information may be transferred to a third-party as a
              result of a sale, acquisition, merger, reorganisation or other
              change of control affecting us. If we sell, merge or transfer any
              part of our business, part of the sale may include Your Personal
              Information.
            </p>

            <p>
              11 WE POST UPDATES ON OUR WEBSITE WHENEVER THERE IS A CHANGE TO
              THIS POLICY
            </p>

            <p className="">11.1</p>
            <p className="">
              We update this Privacy Policy periodically to take into account
              new laws and technology, changes to our operations and practices,
              and to make sure our policy remains appropriate in its changing
              environment. The date of the last revision appears at the top of
              the policy.
            </p>

            <p className="">11.2</p>
            <p className="">
              Any information we hold is governed by the most recent version of
              the policy. You should check this page periodically to ensure that
              You are familiar with any changes made to this Privacy Policy.
            </p>

            <p className="">12 HOW TO MAKE A PRIVACY COMPLAINT?</p>

            <p className="">12.1</p>
            <p className="">
              If You wish to notify us of any privacy complaint You may have
              against us, including if You think that we have failed to comply
              with the Australian Privacy Principles, You may contact us at{" "}
              <a href="mailto:info@bookkeepingstop.com">
                info@bookkeepingstop.com
              </a>
              .
            </p>

            <p className="">12.2</p>
            <p className="">
              We are committed to acknowledging Your complaint in a prompt
              manner and will give You an estimated timeframe for when we will
              respond to Your complaint.
            </p>

            <p className="">12.3</p>
            <p className="">
              It is our intention to resolve Your complaint to Your
              satisfaction. However, if You feel that Your complaint has not
              been satisfactorily addressed or that it is taking too long to
              resolve Your complaint, You are entitled to contact the Office of
              the Australian Information Commissioner (OAIC), on 1300 363 992 or
              the other contact details on the OAIC’s website (
              <a href="http://www.oaic.gov.au">oaic.gov.au</a>), who may
              investigate Your complaint further.
            </p>

            <p className="">
              13 CONTACT US IF YOU HAVE ANY QUESTIONS OR CONCERNS
            </p>

            <p className="">13.1</p>
            <p className="">
              If You have questions, comments, concerns or feedback regarding
              this Privacy Policy or any other privacy or security concern, send
              an email to{" "}
              <a href="mailto:info@bookkeepingstop.com">
                info@bookkeepingstop.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Privacy;
