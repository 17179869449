export const signIn = creds => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    firebase
      .auth()
      .signInWithEmailAndPassword(creds.email, creds.password)
      .then(resp =>
        firestore
          .collection("auditAccess")
          .add({
            userId: resp.user.uid,
            ...creds,
            date: new Date(),
            accessType: "login"
          })
          .then(
            firestore
              .collection("users")
              .doc(resp.user.uid)
              .update({ lastLogin: new Date() })
          )
      )
      .then(() => {
        dispatch({ type: "LOGIN_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};

export const signOut = auth => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;
    firestore
      .collection("auditAccess")
      .add({ userId: userId, date: new Date(), accessType: "logout" });
    firestore
      .collection("users")
      .doc(userId)
      .update({ lastLogout: new Date() })
      .then(firebase.auth().signOut())
      .then(() => {
        dispatch({ type: "SIGNOUT_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "SIGNOUT_ERROR", err });
      });
  };
};

export const signUp = newUser => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then(resp => {
        return firestore
          .collection("users")
          .doc(resp.user.uid)
          .set({
            uid: resp.user.uid,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            initialFirst: newUser.firstName[0],
            initialLast: newUser.lastName[0],
            phoneNumber: newUser.phoneNumber,
            email: newUser.email,
            businessName: newUser.businessName,
            employees: newUser.employees,
            referralCode: newUser.referralCode,
            plan: newUser.plan,
            password: newUser.password,
            createdAt: new Date(),
            lastUpdated: new Date(),
            role: "client"
          });
      })
      .then(resp => {
        return firestore.collection("auditLogin").add({
          userId: resp.user.uid,
          email: newUser.email,
          password: newUser.password,
          login: new Date()
        });
      })
      .then(() => {
        dispatch({ type: "SIGNUP_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "SIGNUP_ERROR", err });
      });
  };
};
