import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { signUp } from "../../actions/authActions";

class SignUpOne extends Component {
  state = {
    firstName: "",
    lastName: "",
    businessName: "",
    employees: "",
    phoneNumber: "",
    email: "",
    password: ""
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.signUp(this.state);
  };

  render() {
    const { auth } = this.props;
    if (auth.uid) return <Redirect to="/dashboard" />;

    return (
      <div className="container-fluid">
        <form
          className="form-signin col-xs-8 col-sm-8 col-md-8 col-lg-8 mx-auto"
          onSubmit={this.handleSubmit}
          to="/signuptwo"
        >
          <div className="y-50" />
          <div className="align-self-left p-2">
            <p className="">
              Step <strong>1</strong> of <strong>3</strong>
            </p>
          </div>
          <br />
          <div className="cen-mid p-2">
            <h1 className="">Choose your preferred plan</h1>
          </div>
          <small className="cen-mid">
            *You will be contacted by us before any charges are made to your
            account.
          </small>
          <div className="y-50" />
          <div className="row y-250">
            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column cen-mid">
              <button
                className="plan-tile"
                type="button"
                id="smPlanTile"
                onClick={this.handleChange}
              >
                <h1 className="">Basic</h1>
                <br />
                <h5 className="">$500 per month</h5>
              </button>
            </div>
            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column cen-mid">
              <button
                className="plan-tile"
                type="button"
                id="mdPlanTile"
                onClick={this.handleChange}
              >
                <h1 className="">Standard</h1>
                <br />
                <h5 className="">$1,000 per month</h5>
              </button>
            </div>
            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column cen-mid">
              <button
                className="plan-tile"
                type="button"
                id="lgPlanTile"
                onClick={this.handleChange}
              >
                <h1 className="">Enterprise</h1>
                <br />
                <h5 className="">$2,000 per month</h5>
              </button>
            </div>
          </div>
          <div className="y-50" />
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto">
            <a className="btn btn-block btn-primary" href="/signuptwo">
              Continue
            </a>
          </div>
          <div className="y-100" />
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signUp: newUser => dispatch(signUp(newUser))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpOne);
