export const updateProfile = props => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;
    firestore
      .collection("users")
      .doc(userId)
      .update({
        ...props,
        lastUpdated: new Date()
      })
      .then(
        firestore.collection("auditProfile").add({
          ...props,
          userId: userId,
          action: "update",
          changedOn: new Date()
        })
      )
      .then(() => {
        dispatch({ type: "UPDATE_PROFILE" });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_PROFILE_ERROR", err });
      });
  };
};

export const updatePassword = props => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;
    firebase
      .auth()
      .currentUser.updatePassword(props.reNewPassword)
      .then(
        firestore
          .collection("users")
          .doc(userId)
          .update({
            password: props.reNewPassword
          })
      )
      .then(
        firestore.collection("auditPassword").add({
          userId: userId,
          action: "change",
          password: props.newPassword,
          changedOn: new Date()
        })
      )
      .then(() => {
        dispatch({ type: "UPDATE_PASSWORD" });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_PASSWORD_ERROR", err });
      });
  };
};

export const deleteUser = props => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;
    firestore
      .collection("auditProfile")
      .add({
        userId: userId,
        action: "deleted",
        changedOn: new Date()
      })
      .then(
        firestore.collection("usersDeleted").add({
          userId: userId,
          changedOn: new Date()
        })
      )
      .then(
        firestore
          .collection("users")
          .doc(userId)
          .delete()
      )
      .then(firebase.auth().currentUser.delete())
      .then(() => {
        dispatch({ type: "USER_DELETED" });
      })
      .catch(err => {
        dispatch({ type: "USER_DELETED_ERROR", err });
      });
  };
};
