import React from "react";
import { Redirect } from "react-router-dom";

const Cover = props => {
  var path = props.location.pathname;
  while (path.charAt(0) === "/") {
    path = path.substr(1);
  }

  if (path === "") return <Redirect to="/home" />;
  var coverTitle = <div>{path.charAt(0).toUpperCase() + path.slice(1)}</div>;

  var coverText;
  var coverButton;

  if (path === "home") {
    coverTitle = (
      <div>
        <div className="d-none d-md-block">
          <h1>
            Welcome to <br />
            Bookkeeping Stop
          </h1>
        </div>
        <h4 className="d-md-none">Welcome</h4>
      </div>
    );
    coverButton = (
      <a className="btn btn-outline-dark" href="tel:+61290300315">
        Call Us
      </a>
    );
  }

  switch (path) {
    case "home":
      coverText = (
        <div className="pt-2">
          <blockquote>
            Our{" "}
            <a className="text-danger font-weight-bold" href="./portal">
              NEW
            </a>{" "}
            web based analytics portal is coming soon!
            <br />
            Call us and we will tell you all about it.
          </blockquote>
        </div>
      );
      break;
    case "about":
      coverText = (
        <div className="pt-2">
          <blockquote>
            Our friendly team of programmers and accountants <br />
            are as talented as they come.
          </blockquote>
        </div>
      );
      break;
    case "services":
      coverText = (
        <div className="pt-2">
          <blockquote>
            All the services below are accessable through our portal <br />
            or any other accounting software of your choosing.
          </blockquote>
        </div>
      );
      break;
    case "pricing":
      coverText = (
        <div className="pt-2">
          <blockquote>
            Our plans are designed to handle all of your business needs. <br />
            Have a look to see which one suits your business best.
          </blockquote>
        </div>
      );
      break;
    case "contact":
      coverText = (
        <div className="pt-2">
          <blockquote>
            Arrange a time to meet us at our Sydney CBD office or <br />
            we can meet you at your place of business.
          </blockquote>
        </div>
      );
      break;
    default:
      coverButton = (
        <div className="pt-2">
          <blockquote> </blockquote>
        </div>
      );
      break;
  }
  return (
    <div>
      <div className="d-none d-md-block">
        <div className={"cover-container-" + path}>
          <div
            className={path === "home" ? "cover-header-home" : "cover-header"}
          >
            {" "}
            <h1>{coverTitle}</h1>
            <h5 className="font-weight-normal font-italic">{coverText}</h5>
            <div>{coverButton}</div>
          </div>
        </div>
      </div>
      <div className="d-md-none">
        <div className={"cover-container-sm-" + path}>
          <div className="cover-header-sm">
            <h4>{coverTitle}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cover;
