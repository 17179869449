import React, { Component } from "react";
import Plans from "./Plans";
import AddOns from "./AddOns";

class Home extends Component {
  state = {
    specialities: ["Medical", "Construction", "Retail"],
    services: [
      "Bookkeeping Servcies",
      "BAS & EOFY Reporting",
      "Custom Analytics",
      "Financial Advice",
    ],
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
        <hr style={{ width: "50%" }} />
        <br />
        <div className="row d-none d-md-flex flex-column">
          <div className="col-md-10 col-lg-8 mx-auto">
            <h5
              className="p-2 text-center font-italic text-muted"
              style={{ lineHeight: "2" }}
            >
              Combining traditional bookkeeping and innovative technology to
              build custom web-based solutions for modern bookkeeping needs.
            </h5>
          </div>
        </div>
        <div className="row d-md-none flex-column">
          <div className="mx-auto">
            <p
              className="p-2 text-center font-italic text-muted"
              style={{ lineHeight: "2" }}
            >
              Combining traditional bookkeeping and innovative technology to
              build custom web-based solutions for modern bookkeeping needs.
            </p>
          </div>
        </div>
        <br />
        <hr style={{ width: "50%" }} />
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
        <div className="row flex-column">
          <div className="mx-auto">
            <h4 className="">Our Services</h4>
          </div>
        </div>
        <div className="card-deck mb-0">
          {this.state.services &&
            this.state.services.map((service, index) => {
              return (
                <div key={index} className="card m-2 cen-mid circle-card">
                  <div className="card-body">
                    <img
                      alt={service}
                      src={require("../../img/home/Service-" + index + ".svg")}
                      style={{ width: "100px", height: "100px" }}
                    />
                    <br />
                    <br />
                    <h5 className="card-title">{service}</h5>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="row mx-auto">
          <a
            className="btn btn-md btn-outline-primary mx-auto"
            href="/services"
          >
            Learn more
          </a>
        </div>
        <div className="y-100" />
        <Plans />
        <div className="y-100" />
        <AddOns />
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
        <div className="row flex-column">
          <div className="mx-auto">
            <h4 className="">Specialities</h4>
          </div>
        </div>
        <div className="card-deck m-2">
          {this.state.specialities &&
            this.state.specialities.map((speciality, index) => {
              return (
                <div key={index} className="card m-4 cen-mid circle-card">
                  <div className="card-body">
                    <img
                      alt={speciality}
                      src={require("../../img/home/" + speciality + ".png")}
                      style={{ width: "100px", height: "100px" }}
                    />
                    <br />
                    <br />
                    <h5 className="card-title">{speciality}</h5>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="y-50" />
        {/* <div className="row y-300 d-none d-md-flex">
          <div className="d-flex flex-column mx-auto">
            <div className="col-stretch lef-mid">
              <h4 className="p-2">Not sure which plan suits your needs?</h4>
            </div>
            <div className="col-stretch rig-mid">
              <h4 className="p-2 text-muted">
                Want to negotiate a better price?
              </h4>
            </div>
            <div className="col-stretch cen-mid">
              <h4 className="font-weight-normal">
                <a className="p-2 no-decoration" href="tel:+61290300315">
                  Call Us
                </a>
                and we can discuss things with you further.
              </h4>
            </div>
          </div>
        </div>
        <div className="row y-200 d-md-none">
          <div className="d-flex flex-column mx-auto">
            <div className="col-stretch lef-mid">
              <p className="p-2 font-6">
                Not sure which plan suits your needs?
              </p>
            </div>
            <div className="col-stretch rig-mid">
              <p className="p-2 font-6 text-muted">
                Want to negotiate a better price?
              </p>
            </div>
            <div className="col-stretch cen-mid">
              <p className="p-2 font-6">
                <a className="no-decoration" href="tel:+61290300315">
                  Call Us
                </a>{" "}
                and we can discuss things with you further.
              </p>
            </div>
          </div>
        </div> */}
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
      </div>
    );
  }
}

/*
constructor(props) {
    super(props);
    this.state = {
      showSmall: false,
      showMedium: false,
      showLarge: false,
      showOne: false,
      showTwo: false,
      showThree: false,
      showFour: false
    };
  }

  expandSmall = () => {
    this.setState({
      showSmall: !this.state.showSmall
    });
  };

  expandMedium = () => {
    this.setState({
      showMedium: !this.state.showMedium
    });
  };

  expandLarge = () => {
    this.setState({
      showLarge: !this.state.showLarge
    });
  };

  oneHover = () => {
    this.setState({
      showOne: true
    });
  };

  oneHoverOut = () => {
    this.setState({
      showOne: false
    });
  };
  

 twoHover = () => {
  this.setState({
    showTwo: true
  });
};

twoHoverOut = () => {
  this.setState({
    showTwo: false
  });
};


threeHover = () => {
  this.setState({
    showThree: true
  });
};


threeHoverOut = () => {
  this.setState({
    showThree: false
  });
};


fourHover = () => {
  this.setState({
    showFour: true
  });
};


fourHoverOut = () => {
  this.setState({
    showFour: false
  });
};

        <div className="y-50" />
        <div className="row y-100 bg-bk" />
        <div className="row y-100 bg-bk" onMouseOver={this.oneHover.bind(this)}>
          { onMouseOut={this.fourHoverOut.bind(this)} }
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 d-flex flex-column" />
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column ">
            <div className="col-stretch cen-mid">
              <h1
                className={
                  this.state.showOne
                    ? "step-number-active cen-mid"
                    : "step-number cen-mid"
                }
              >
                1
              </h1>
            </div>
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 d-flex flex-column">
            <div className="col-stretch cen-mid">
              <img
                className={
                  this.state.showOne
                    ? "step-icon-active cen-mid"
                    : "step-icon cen-mid"
                }
                src={require("../../img/home/calculator.png")}
                alt="Icon"
              />
            </div>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column ">
            <div className="col-stretch cen-mid">
              <h4
                className={
                  this.state.showOne
                    ? "step-text-active cen-mid"
                    : "step-text cen-mid"
                }
              >
                Monthly meeting with your dedicated bookkeeper.{" "}
              </h4>
            </div>
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 d-flex flex-column" />
        </div>

        <div className="row y-100 bg-bk">
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 d-flex flex-column" />
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
            <div className="col-stretch cen-mid" />
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 d-flex flex-column">
            <div className="col-stretch cen-mid">
              <h1
                className={
                  this.state.showOne
                    ? "step-arrow-active cen-mid"
                    : "step-arrow cen-mid"
                }
              >
                <br />|<br />|<br />
                &darr;
              </h1>
            </div>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
            <div className="col-stretch cen-mid" />
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 d-flex flex-column" />
        </div>

        <div className="row y-50 bg-bk" />
        <div className="row y-100 bg-bk" onMouseOver={this.twoHover.bind(this)}>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 d-flex flex-column" />
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
            <div className="col-stretch cen-mid">
              <h1
                className={
                  this.state.showTwo
                    ? "step-number-active cen-mid"
                    : "step-number cen-mid"
                }
              >
                2
              </h1>
            </div>
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 d-flex flex-column">
            <div className="col-stretch cen-mid">
              <img
                className={
                  this.state.showTwo
                    ? "step-icon-active cen-mid"
                    : "step-icon cen-mid"
                }
                src={require("../../img/home/calculator.png")}
                alt="Icon"
              />
            </div>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
            <div className="col-stretch cen-mid">
              <h4
                className={
                  this.state.showTwo
                    ? "step-text-active cen-mid"
                    : "step-text cen-mid"
                }
              >
                Mail or email your reciepts.
              </h4>
            </div>
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 d-flex flex-column" />
        </div>

        <div className="row y-100 bg-bk">
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 d-flex flex-column" />
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
            <div className="col-stretch cen-mid" />
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 d-flex flex-column">
            <div className="col-stretch cen-mid">
              <h1
                className={
                  this.state.showTwo
                    ? "step-arrow-active cen-mid"
                    : "step-arrow cen-mid"
                }
              >
                <br />|<br />|<br />
                &darr;
              </h1>
            </div>{" "}
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
            <div className="col-stretch cen-mid" />
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 d-flex flex-column" />
        </div>

        <div className="row y-50 bg-bk" />
        <div
          className="row y-100 bg-bk"
          onMouseOver={this.threeHover.bind(this)}
        >
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 d-flex flex-column" />
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
            <div className="col-stretch cen-mid">
              <h1
                className={
                  this.state.showThree
                    ? "step-number-active cen-mid"
                    : "step-number cen-mid"
                }
              >
                3
              </h1>
            </div>
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 d-flex flex-column">
            <div className="col-stretch cen-mid">
              <img
                className={
                  this.state.showThree
                    ? "step-icon-active cen-mid"
                    : "step-icon cen-mid"
                }
                src={require("../../img/home/calculator.png")}
                alt="Icon"
              />
            </div>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
            <div className="col-stretch cen-mid">
              <h4
                className={
                  this.state.showThree
                    ? "step-text-active cen-mid"
                    : "step-text cen-mid"
                }
              >
                Send timesheets and subcontractor invoices.
              </h4>
            </div>
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 d-flex flex-column" />
        </div>

        <div className="row y-100 bg-bk">
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 d-flex flex-column" />
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
            <div className="col-stretch cen-mid" />
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 d-flex flex-column">
            <div className="col-stretch cen-mid">
              <h1
                className={
                  this.state.showThree
                    ? "step-arrow-active cen-mid"
                    : "step-arrow cen-mid"
                }
              >
                <br />|<br />|<br />
                &darr;
              </h1>
            </div>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
            <div className="col-stretch cen-mid" />
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 d-flex flex-column" />
        </div>

        <div className="row y-50 bg-bk" />
        <div
          className="row y-100 bg-bk"
          onMouseOver={this.fourHover.bind(this)}
        >
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 d-flex flex-column" />
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
            <div className="col-stretch cen-mid">
              <h1
                className={
                  this.state.showFour
                    ? "step-number-active cen-mid"
                    : "step-number cen-mid"
                }
              >
                4
              </h1>
            </div>
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 d-flex flex-column">
            <div className="col-stretch cen-mid">
              <img
                className={
                  this.state.showFour
                    ? "step-icon-active cen-mid"
                    : "step-icon cen-mid"
                }
                src={require("../../img/home/calculator.png")}
                alt="Icon"
              />
            </div>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
            <div className="col-stretch cen-mid">
              <h4
                className={
                  this.state.showFour ? "step-text-active" : "step-text"
                }
              >
                Login to the online portal to get an overview of your finances
              </h4>
            </div>
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 d-flex flex-column" />
        </div>
        <div className="y-100 bg-bk" />
        */

export default Home;
