import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import Graph from "./Graph";
import Graph1 from "./Graph1";
import Graph2 from "./Graph2";
import Graph3 from "./Graph3";
import Graph4 from "./Graph4";
import Graph5 from "./Graph5";
import Graph6 from "./Graph6";
import Graph7 from "./Graph7";

class Analytics extends Component {
  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div className="container-fluid">
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
        <Graph />
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
        <Graph1 />
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
        <Graph2 />
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
        <Graph3 />
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
        <Graph4 />
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
        <Graph5 />
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
        <Graph6 />
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
        <Graph7 />
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    tickets: state.firestore.ordered.tickets,
    auth: state.firebase.auth
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "tickets" }])
)(Analytics);
