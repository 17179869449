import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";

class ClientHome extends Component {
  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div className="container-fluid">
        <div className="y-200" />
        <div className="row">
          <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 d-flex flex-column mx-auto">
            <div className="y-100 cen-mid">
              <h5 className="">Currently no data.</h5>
            </div>
          </div>
        </div>
        <div className="y-200" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    tickets: state.firestore.ordered.tickets,
    auth: state.firebase.auth
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "tickets" }])
)(ClientHome);
