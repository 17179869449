import React, { Component } from "react";
import { connect } from "react-redux";
//import { Redirect } from "react-router-dom";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { editTicket, editPlan, editFeature } from "../../actions/viewActions";
import ViewTickets from "./ViewTickets";
import ViewPlans from "./ViewPlans";
import ViewFeatures from "./ViewFeatures";

class View extends Component {
  render() {
    const { tickets, plans, features } = this.props;
    //const { auth, tickets, plans } = this.props;
    //if (!auth.uid) return <Redirect to="/signin" />;
    console.log(this.props);

    return (
      <div className="container-fluid">
        <div className="y-50" />
        <div className="y-100 cen-mid">
          <h3 className="font-weight-normal">Features</h3>
        </div>
        <div className="">
          {features &&
            features.map((feature) => {
              return (
                <ViewFeatures feature={feature} key={feature.id.toString()} />
              );
            })}
        </div>
        <div className="y-100" /> <div className="y-50" />
        <div className="y-100 cen-mid">
          <h3 className="font-weight-normal">Plans</h3>
        </div>
        <div className="">
          {plans &&
            plans.map((plan) => {
              return <ViewPlans plan={plan} key={plan.id.toString()} />;
            })}
        </div>
        <div className="y-100" />
        <div className="y-100 cen-mid">
          <h3 className="font-weight-normal">Tickets</h3>
        </div>
        <div className="">
          {tickets &&
            tickets.map((ticket) => {
              return <ViewTickets ticket={ticket} key={ticket.id.toString()} />;
            })}
        </div>
        <div className="y-100" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    auth: state.firebase.auth,
    tickets: state.firestore.ordered.tickets,
    plans: state.firestore.ordered.plans,
    features: state.firestore.ordered.features,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editPlan: (props) => dispatch(editPlan(props)),
    editTicket: (props) => dispatch(editTicket(props)),
    editFeature: (props) => dispatch(editFeature(props)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    {
      collection: "tickets",
      orderBy: ["lastUpdated", "desc"],
    },
    {
      collection: "plans",
      orderBy: ["size", "asc"],
    },
    {
      collection: "features",
      orderBy: ["size", "asc"],
    },
  ])
)(View);
