export const createTicket = ticket => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;
    firestore
      .collection("tickets")
      .add({
        ...ticket,
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        userId: userId,
        createdAt: new Date(),
        lastUpdated: new Date()
      })
      .then(doc =>
        firestore.collection("auditTicket").add({
          ...ticket,
          ticketId: doc.id,
          userId: userId,
          action: "create",
          changedOn: new Date()
        })
      )
      .then(() => {
        dispatch({ type: "ADD_TICKET" });
      })
      .catch(err => {
        dispatch({ type: "ADD_TICKET_ERROR", err });
      });
  };
};

export const updateTicket = ticket => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;
    const id = ticket.id;
    firestore
      .collection("tickets")
      .doc(id)
      .update({
        ...ticket,
        lastUpdated: new Date()
      })
      .then(
        firestore.collection("auditTicket").add({
          ...ticket,
          ticketId: id,
          userId: userId,
          action: "update",
          changedOn: new Date()
        })
      )
      .then(() => {
        dispatch({ type: "UPDATE_TICKET" });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_TICKET_ERROR", err });
      });
  };
};

export const deleteTicket = ticket => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;
    firestore
      .collection("auditTicket")
      .add({
        ...ticket,
        ticketId: ticket.id,
        userId: userId,
        action: "delete",
        changedOn: new Date()
      })
      .then(
        firestore
          .collection("tickets")
          .doc(ticket.id)
          .delete()
      )
      .then(() => {
        dispatch({ type: "DELETE_TICKET" });
      })
      .catch(err => {
        dispatch({ type: "DELETE_TICKET_ERROR", err });
      });
  };
};
