import React, { Component } from "react";

class About extends Component {
  state = {
    services: ["Bookkeepers", "Accountants", "BAS Agents", "Programmers"]
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
        <hr style={{ width: "50%" }} />
        <br />
        <div className="row d-none d-md-flex flex-column">
          <div className="col-md-10 col-lg-8 mx-auto">
            <h4 className="text-center">Our Vision</h4>
            <h5
              className="p-2 text-center font-italic text-muted"
              style={{ lineHeight: "2" }}
            >
              Help alleviate the ongoing burden of mainting financial records
              for small to medium sized Australian businesses through the use of
              creative technology.
            </h5>
          </div>
        </div>
        <div className="row d-md-none flex-column">
          <div className="mx-auto col">
            <h4 className="text-center">Our Vision</h4>
            <p
              className="p-2 text-center font-italic text-muted"
              style={{ lineHeight: "2" }}
            >
              Help alleviate the ongoing burden of mainting financial records
              for small to medium sized Australian businesses through the use of
              creative technology.{" "}
            </p>
          </div>
        </div>
        <br />
        <hr style={{ width: "50%" }} />
        <div className="y-50" />
        <br />
        <div className="row d-none d-md-flex flex-column">
          <div className="col-md-10 col-lg-8 mx-auto">
            <h4 className="text-center pb-4 ">Our Team</h4>
            <p className="p-2 text-left">
              We are a young group of ambitious professionals working towards
              moving our clients from their outdating bookkeeping practices to
              our new web based accounting software that is currently in
              development. Our team includes;
            </p>
            <div className="card-deck">
              <li className="card m-4 cen-mid circle-card">Programmers</li>
              <li className="card m-4 cen-mid circle-card">Accountants</li>
              <li className="card m-4 cen-mid circle-card">Bookkeepers</li>
              <li className="card m-4 cen-mid circle-card">BAS Agents</li>
            </div>
          </div>
        </div>
        <div className="row d-md-none flex-column">
          <div className="mx-auto col">
            <h4 className="text-center">Our Team</h4>
            <p
              className="p-2 text-center font-italic text-muted"
              style={{ lineHeight: "2" }}
            >
              We are a young group of ambitious professionals working towards
              moving our clients from their outdating bookkeeping practices to
              our new web based accounting software that is currently in
              development. Our team includes;
            </p>
            <div className="card-deck">
              <li className="card m-2 cen-mid circle-card">Programmers</li>
              <li className="card m-2 cen-mid circle-card">Accountants</li>
              <li className="card m-2 cen-mid circle-card">Bookkeepers</li>
              <li className="card m-2 cen-mid circle-card">BAS Agents</li>
            </div>
          </div>
        </div>
        <br />
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
      </div>
    );
  }
}

export default About;

/*
<div className="card-deck m-2">
          {this.state.services &&
            this.state.services.map((service, index) => {
              return (
                <div key={index} className="card m-4 cen-mid circle-card">
                  <div className="card-body">
                    <img
                      alt={service}
                      src={require("../../img/about/About-" + index + ".svg")}
                      style={{ width: "100px", height: "100px" }}
                    />
                    <br />
                    <br />
                    <h5 className="card-title">{service}</h5>
                  </div>
                </div>
              );
            })}
        </div>
*/

/*

      <div className="row d-none d-md-flex flex-column">
        <div className="mx-auto">
          <h4 className="">Our Team</h4>
          <small className="text-center">*Photos on the way</small>
        </div>
        <div className="card-deck m-2">
          <div className="card m-4 cen-mid circle-card">
            <div className="card-body">
              <img
                alt="Angelica"
                src={require("../../img/Angelica.jpg")}
                style={{ width: "200px", height: "200px" }}
              />
              <br />
              <br />
              <h5 className="card-title">Angelica</h5>
            </div>
          </div>
          <div className="card m-4 cen-mid circle-card">
            <div className="card-body">
              <img
                alt="Construction"
                src={require("../../img/Dina.jpg")}
                style={{ width: "200px", height: "200px" }}
              />
              <br />
              <br />
              <h5 className="card-title">Dina</h5>
            </div>
          </div>
          <div className="card m-4 cen-mid circle-card">
            <div className="card-body">
              <img
                alt="Retail"
                src={require("../../img/Moreby.jpg")}
                style={{ width: "200px", height: "200px" }}
              />
              <br />
              <br />
              <h5 className="card-title">Moreby</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="row d-md-none flex-column">
        <div className="mx-auto">
          <h4 className="">Our Team</h4>
        </div>
        <div className="card-deck">
          <div className="card cen-mid circle-card">
            <div className="card-body">
              <img
                alt="Medical"
                src={require("../../img/Angelica.jpg")}
                style={{ width: "150px", height: "150px" }}
              />
              <br />
              <br />
              <p className="card-title font-6">Angelica</p>
            </div>
          </div>
          <div className="card cen-mid circle-card">
            <div className="card-body">
              <img
                alt="Construction"
                src={require("../../img/Dina.jpg")}
                style={{ width: "150px", height: "150px" }}
              />
              <br />
              <br />
              <p className="card-title font-6">Dina</p>
            </div>
          </div>
          <div className="card cen-mid circle-card">
            <div className="card-body">
              <img
                alt="Retail"
                src={require("../../img/Moreby.jpg")}
                style={{ width: "150px", height: "150px" }}
              />
              <br />
              <br />
              <p className="card-title font-6">Moreby</p>
            </div>
          </div>
        </div>
      </div>

      <div className="y-100 d-none d-md-block" />
      <div className="y-50 d-md-none" />
    </div>
  );
};
*/

/*
<div className="row flex-column">
        <div className="mx-auto">
          <h4 className="">Testimonials</h4>
        </div>
        <div className="card-deck col-lg-10 mx-auto">
          <div className="row">
            <div className="card m-4 circle-card text-center">
              <div className="card-body">
                <p className="text-muted" style={{ lineHeight: "2" }}>
                  "Quotes about business will go here. Quotes about business
                  will go here. Quotes about business will go here. Quotes about
                  business will go here. Quotes about business will go here.
                  Quotes about business will go here."
                </p>
                <strong className="font-italic">- Angelica, BK Stop</strong>
              </div>
            </div>
            <div className="card m-4 circle-card text-center">
              <div className="card-body">
                <p className="text-muted" style={{ lineHeight: "2" }}>
                  "Quotes about business will go here. Quotes about business
                  will go here. Quotes about business will go here. Quotes about
                  business will go here. Quotes about business will go here.
                  Quotes about business will go here."
                </p>
                <strong className="font-italic">- Angelica, BK Stop</strong>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card m-4 circle-card text-center">
              <div className="card-body">
                <p className="text-muted" style={{ lineHeight: "2" }}>
                  "Quotes about business will go here. Quotes about business
                  will go here. Quotes about business will go here. Quotes about
                  business will go here. Quotes about business will go here.
                  Quotes about business will go here."
                </p>
                <strong className="font-italic">- Angelica, BK Stop</strong>
              </div>
            </div>
            <div className="card m-4 circle-card text-center">
              <div className="card-body">
                <p className="text-muted" style={{ lineHeight: "2" }}>
                  "Quotes about business will go here. Quotes about business
                  will go here. Quotes about business will go here. Quotes about
                  business will go here. Quotes about business will go here.
                  Quotes about business will go here."
                </p>
                <strong className="font-italic">- Angelica, BK Stop</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="y-100 d-none d-md-block" />
      <div className="y-50 d-md-none" />
*/
