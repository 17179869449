import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

class Plans extends Component {
  render() {
    const { plans } = this.props;

    return (
      <div>
        <div className="row">
          <div className="d-flex flex-column mx-auto">
            <div className="col col-stretch cen-mid">
              <h4 className="p-2">Plans available</h4>
            </div>
            <div className="col-stretch cen-top">
              <p className="p-2 font-weight-normal">
                Take a look at our carefully packaged plans to see which one
                suits your business needs best.
              </p>
            </div>
          </div>
        </div>

        <div className="card-deck m-4">
          {plans &&
            plans.map((plan, index) => {
              return (
                <div key={index} className="card shadow-sm text-center">
                  <div className="card-header">
                    <h4 className="p-2 font-weight-normal">{plan.planName}</h4>
                  </div>
                  <div className="card-body m-0 mt-2">
                    <h3 className="p-2 card-title pricing-card-title">
                      $
                      {plan.planPrice
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
                      <small className="text-muted">/ mo</small>
                    </h3>
                    <ul className="list-unstyled m-0 mt-2">
                      {plan.planFeatures &&
                        plan.planFeatures.map((features, index) => {
                          return (
                            <li
                              key={index}
                              className="p-1 text-muted font-italic"
                            >
                              {features}
                            </li>
                          );
                        })}
                      <li className="p-1 text-muted">...</li>
                    </ul>
                    <ul className="list-unstyled m-0">
                      {plan.extras &&
                        plan.extras.map((extra, index) => {
                          return (
                            <li key={index} className="font-6 p-1 font-italic">
                              {extra}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  <div className="card-footer bg-transparent border-white mb-4">
                    <a
                      className="btn btn-md btn-outline-primary p-2"
                      href="/pricing"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      /*
                  <li>- - -</li>
                  <div className="text-muted">
                    <li>20 users included</li>
                    <li>10 GB of storage</li>
                    <li>Priority email support</li>
                    <li>Help center access</li>
                  </div>
                  {this.state.showSmall ? (
                    <div>
                      <li>20 users included</li>
                      <li>10 GB of storage</li>
                      <li>Priority email support</li>
                      <li>Help center access</li>
                    </div>
                  ) : null}
                </ul>
                <button
                  className="textButton"
                  onClick={() => this.expandSmall()}
                  id="moreButton"
                >
                  {this.state.showSmall ? (
                    <div>View less &uarr;</div>
                  ) : (
                    <div>View more &darr;</div>
                  )}
                </button> */
    );
  }
}

const mapStateToProps = state => {
  return {
    plans: state.firestore.ordered.plans
  };
};

export default compose(
  firestoreConnect([
    {
      collection: "plans",
      where: ["size", ">=", "1"],
      orderBy: ["size", "asc"]
    }
  ]),
  connect(mapStateToProps)
)(Plans);
