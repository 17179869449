import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { updatePassword } from "../../actions/profileActions";
import { Redirect } from "react-router-dom";

class ChangePassword extends Component {
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.updatePassword(this.state);
  };

  render() {
    const { passwordError, auth, passwordUpdateSuccess } = this.props;
    if (!auth.uid) return <Redirect to="/home" />;

    return (
      <div className="container-fluid">
        <form
          className="form-horizontal m-4"
          onSubmit={this.handleSubmit}
          to="/account"
        >
          <div className="y-100 cen-mid">
            <h4 className="">Change Password</h4>
          </div>
          <div className="text-danger text-center">
            {passwordError ? <p>{passwordError}</p> : null}
          </div>
          <div className="text-danger text-center">
            {passwordUpdateSuccess ? <p>{passwordUpdateSuccess}</p> : null}
          </div>
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="text"
            >
              Old password:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="password"
                id="oldPassword"
                className="form-control"
                placeholder="Enter old password"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="text"
            >
              New password:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="password"
                id="newPassword"
                className="form-control"
                placeholder="Enter new password"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="tel"
            >
              Re-enter new password:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="password"
                id="reNewPassword"
                className="form-control"
                placeholder="Re-enter new password"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row cen-mid">
            <input
              className="btn btn-md btn-primary"
              type="submit"
              value="Change Password"
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const profile = state.firebase.profile;
  return {
    profile: profile,
    auth: state.firebase.auth,
    passwordError: state.profile.passwordError,
    passwordUpdateSuccess: state.profile.passwordUpdateSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updatePassword: props => dispatch(updatePassword(props))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: "users" }])
)(ChangePassword);
