const initState = {
  profileUpdateSuccess: null,
  profileError: null,
  passwordUpdateSuccess: null,
  passwordError: null,
  userDeletedSuccess: null,
  userDeletedError: null
};

const profileReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_PROFILE":
      console.log("Updated profile.");
      return {
        ...state,
        profileUpdateSuccess: "Updated profile.",
        profileError: null
      };

    case "UPDATE_PROFILE_ERROR":
      console.log("Update profile error.");
      return {
        ...state,
        profileUpdateSuccess: null,
        profileError: action.err.message
      };

    case "UPDATE_PASSWORD":
      console.log("Updated password.");
      return {
        ...state,
        passwordUpdateSuccess: "Updated password",
        passwordError: null
      };

    case "UPDATE_PASSWORD_ERROR":
      console.log("Update password error.");
      return {
        ...state,
        passwordUpdateSuccess: null,
        passwordError: action.err.message
      };

    case "USER_DELETED":
      console.log("User deleted.");
      return {
        ...state,
        userDeletedSuccess: "User deleted",
        userDeletedError: null
      };

    case "USER_DELETED_ERROR":
      console.log("User deleted error.");
      return {
        ...state,
        userDeletedSuccess: null,
        userDeletedError: action.err.message
      };

    default:
      return state;
  }
};

export default profileReducer;
