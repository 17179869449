import React, { Component } from "react";

class Portal extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="y-200" />
        <div className="row">
          <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 d-flex flex-column mx-auto">
            <div className="y-100 cen-mid">
              <h5 className="">Coming Soon!</h5>
            </div>
          </div>
        </div>
        <div className="y-300" />
      </div>
    );
  }
}

export default Portal;
