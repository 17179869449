import React from "react";
import { connect } from "react-redux";
import { signOut } from "../../actions/authActions";

const SignedInLinks = props => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top shadow navbar-height">
      <a
        className="btn btn-lg btn-primary px-3 ml-4 text-capitalize"
        href="/clientHome"
      >
        {props.profile.initialFirst}
        {props.profile.initialLast}
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarToggler"
        aria-controls="navbarToggler"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>

      <div className="collapse navbar-collapse" id="navbarToggler">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item mx-2">
            <a className="nav-link" href="/account">
              Account
            </a>
          </li>
          <li className="nav-item mx-2">
            <a className="nav-link" href="/activity">
              Transactions
            </a>
          </li>
          <li className="nav-item mx-2">
            <a className="nav-link" href="/clientHome">
              Analytics
            </a>
          </li>
          <li className="nav-item mx-2">
            <a className="nav-link" href="/reports">
              Reports
            </a>
          </li>
          <li className="nav-item mx-2">
            <a className="nav-link" href="/upload">
              Upload
            </a>
          </li>
        </ul>
        <div className="d-flex">
          <ul className="navbar-nav">
            <li className="nav-item mx-2">
              <a className="nav-link" href="/support">
                Feature Request
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link mr-4"
                href="/home"
                onClick={() => props.signOut()}
              >
                <i className="fas fa-sign-out-alt mr-1 mb-1" /> Sign Out
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = state => {
  console.log(state);
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: auth => dispatch(signOut(auth))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignedInLinks);
