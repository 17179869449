import React, { Component } from "react";
import { connect } from "react-redux";
import { updateProfile } from "../../actions/profileActions";
import { Redirect } from "react-router-dom";

class Profile extends Component {
  state = { initialFirst: null, initialLast: null };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
    if (this.state.firstName != null)
      this.setState({ initialFirst: this.state.firstName.charAt(0) });
    if (this.state.lastName != null)
      this.setState({ initialLast: this.state.lastName.charAt(0) });
    console.log(this.state);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.updateProfile(this.state);
  };

  render() {
    const { profile, profileError, auth, profileUpdateSuccess } = this.props;
    console.log(this.props);

    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div className="container-fluid">
        <form
          className="form-horizontal m-4"
          onSubmit={this.handleSubmit}
          to="/profile"
        >
          <div className="y-100 cen-mid">
            <h4 className="">Update Details</h4>
          </div>
          <div className="cen-mid">
            {profileError ? (
              <div className="text-danger pb-4">
                <p>{profileError}</p>
              </div>
            ) : null}
          </div>
          <div className="pb-4 cen-mid">
            {profileUpdateSuccess ? (
              <div className="text-primary">
                <p>{profileUpdateSuccess}</p>
              </div>
            ) : null}
          </div>
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="text"
            >
              First name:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="text"
                id="firstName"
                className="form-control"
                defaultValue={profile.firstName}
                autoFocus
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="text"
            >
              Last name:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="text"
                id="lastName"
                className="form-control"
                defaultValue={profile.lastName}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="businessName"
            >
              Business name:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="text"
                id="businessName"
                className="form-control"
                defaultValue={profile.businessName}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="tel"
            >
              Phone number:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="number"
                min="0"
                id="phoneNumber"
                className="form-control"
                defaultValue={profile.phoneNumber}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="email"
            >
              Email:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="email"
                id="email"
                className="form-control"
                defaultValue={profile.email}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="employees"
            >
              Number of employees:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="number"
                id="employees"
                className="form-control"
                defaultValue={profile.employees}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="referralCode"
            >
              Referral code:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <p className="">{profile.referralCode}</p>
            </div>
          </div>
          <br />
          <div className="form-group row cen-mid">
            <input
              className="btn btn-md btn-primary"
              type="submit"
              value="Update Details"
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log(state);
  return {
    profile: state.firebase.profile,
    profileUpdateSuccess: state.profile.profileUpdateSuccess,
    profileError: state.profile.profileError,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProfile: props => dispatch(updateProfile(props))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
