export const uploadFile = props => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;
    //var metadata = { customMetadata: props.file.name };
    var upload = firebase.storage().ref(userId + "/" + props.file.name);
    upload
      .put(props.file)
      .then(
        firebase
          .storage()
          .refFromURL(
            "gs://bkstop-a40d9.appspot.com/" + userId + "/" + props.file.name
          )
          .getDownloadURL()
          .then(url => {
            firestore.collection("auditFiles").add({
              url: url
            });
          })
      )
      .then(() => {
        dispatch({ type: "UPLOAD_FILE" });
      })
      .catch(err => {
        dispatch({ type: "UPLOAD_FILE_ERROR", err });
      });
  };
};

export const hwReceipts = props => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    //const userId = getState().firebase.auth.uid;
    //var metadata = { customMetadata: props.file.name };
    let storageRef = firebase
      .storage()
      .ref(
        "hw/" +
        props.date.substr(0, 4) +
        "/" +
        props.date +
        "-" +
        props.company +
        "-" +
        props.totalAmount
      );

    storageRef
      .put(props.file)
      .then(snapshot =>
        snapshot.storageRef.getDownloadURL().then(url => {
          console.log(' * new url', url)
        })
          // firebase
          //       .storage()
          //       .refFromURL(
          //         "gs://bkstop-a40d9.appspot.com/" +
          //         "hw/" +
          //         props.date.substr(0, 4) +
          //         "/" +
          //         props.date +
          //         "-" +
          //         props.company +
          //         "-" +
          //         props.totalAmount
          //       )
          //       .getDownloadURL()
          //       .then(url => {
          //         firestore
          //           .collection("pettycash")
          //           .doc("hw")
          //           .collection(props.date.substr(0, 4))
          //           .add({
          //             bucketName: "bkstop-a40d9.appspot.com",
          //             bucket: "gs://bkstop-a40d9.appspot.com/",
          //             filePath:
          //               "hw/" +
          //               props.date.substr(0, 4) +
          //               "/" +
          //               props.date +
          //               "-" +
          //               props.company +
          //               "-" +
          //               props.totalAmount,
          //             ref:
          //               "gs://bkstop-a40d9.appspot.com/" +
          //               "hw/" + props.date.substr(0, 4) + "/" + props.date + "-" + props.company + "-" + props.totalAmount,
          //             fileName: props.date + "-" + props.company + "-" + props.totalAmount,
          //             employeeName: props.employeeName,
          //             company: props.company,
          //             date: props.date,
          //             reason: props.reason,
          //             totalAmount: props.totalAmount,
          //             created: new Date(),
          //             url: url
          //         });
          //     })
          // )
          .then(() => {
            dispatch({ type: "UPLOAD_RECIEPT" });
          })
          .catch(err => {
            dispatch({ type: "UPLOAD_RECIEPT_ERROR", err });
          }));
  };
};
