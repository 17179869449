const initState = {
  addTicketSuccess: null,
  addTicketError: null,
  updateTicketSuccess: null,
  updateTicketError: null,
  ticketDeletedSuccess: null,
  ticketDeletedError: null
};

const ticketReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_TICKET":
      console.log("Created ticket.");
      return {
        ...state,
        addTicketSuccess: "Created ticket",
        addTicketError: null
      };

    case "CREATE_TICKET_ERROR":
      console.log("Create ticket error.");
      return {
        ...state,
        addTicketSuccess: null,
        addTicketError: action.err.message
      };

    case "UPDATE_TICKET":
      console.log("Updated ticket.");
      return {
        ...state,
        ticketUpdateSuccess: "Updated ticket",
        ticketUpdateError: null
      };

    case "UPDATE_TICKET_ERROR":
      console.log("Updated ticket error.");
      return {
        ...state,
        ticketUpdateSuccess: null,
        ticketUpdateError: action.err.message
      };

    case "DELETE_TICKET":
      console.log("Deleted ticket.");
      return {
        ...state,
        ticketDeletedSuccess: "Deleted ticket",
        ticketDeletedError: null
      };

    case "DELETE_TICKET_ERROR":
      console.log("Deleted ticket error.");
      return {
        ...state,
        ticketDeletedSuccess: null,
        ticketDeletedError: action.err.message
      };

    default:
      return state;
  }
};

export default ticketReducer;
