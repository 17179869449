import React, { Component } from "react";
import { createTicket } from "../../actions/ticketActions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class TicketCreate extends Component {
  state = {
    title: "",
    content: ""
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.createTicket(this.state);
    //this.props.history.push("/");
  };

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />;
    return (
      <div className="container text-center">
        <main role="main">
          <div className="flex-column align-items-center p-4 bg-white">
            <form
              onSubmit={this.handleSubmit}
              className="form-signin col-6 mx-auto"
              to="/support"
            >
              <div className="text-center mb-4">
                <h1 className="h3 mb-3 font-weight-normal">Create Ticket</h1>
              </div>
              <div className="form-label-group">
                <input
                  type="text"
                  id="title"
                  className="form-control"
                  placeholder="Title"
                  required
                  autoFocus
                  onChange={this.handleChange}
                />
              </div>
              <br />
              <div className="form-group-lg">
                <textarea
                  id="content"
                  placeholder="Enter text"
                  className="form-control"
                  rows="6"
                  required
                  onChange={this.handleChange}
                />
              </div>
              <br />
              <button className="btn btn-lg btn-primary btn-md" type="submit">
                Create
              </button>
              <br />
            </form>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createTicket: ticket => dispatch(createTicket(ticket))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketCreate);
