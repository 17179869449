import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { contactUs } from "../../actions/contactActions";

class ContactForm extends Component {
  state = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    subject: "",
    message: ""
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
    console.log(this.state);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.contactUs(this.state);
  };

  render() {
    const { contactUsError, contactUsSuccess } = this.props;

    return (
      <div className="container-fluid">
        <form
          className="form-horizontal mt-4"
          onSubmit={this.handleSubmit}
          to="/contact"
        >
          <div className="cen-mid">
            {contactUsError ? (
              <div className="text-danger pb-4">
                <p>{contactUsError}</p>
              </div>
            ) : null}
          </div>
          <div className="pb-4 cen-mid">
            {contactUsSuccess ? (
              <div className="text-primary">
                <p>{contactUsSuccess}</p>
              </div>
            ) : null}
          </div>
          <div className="form-group">
            <div className="col-sm-12 offset-sm-0 col-md-12 offset-md-0 col-lg-9 offset-lg-1">
              <div className="form-group row">
                <label
                  htmlFor="firstName"
                  className="col-sm-3 col-md-2 col-lg-2 control-label mb-3"
                >
                  First name:
                </label>
                <div className="col-sm-9 col-md-4 col-lg-4 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    placeholder="Enter first name"
                    autoFocus
                    required
                    onChange={this.handleChange}
                  />
                </div>
                <label
                  htmlFor="lastName"
                  className="col-sm-3 col-md-2 col-lg-2 control-label"
                >
                  Last Name:
                </label>
                <div className="col-sm-9 col-md-4 col-lg-4">
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    placeholder="Enter last name"
                    required
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="phoneNumber"
                  className="col-sm-3 col-md-2 col-lg-2 control-label mb-3"
                >
                  Phone Number:
                </label>
                <div className="col-sm-9 col-md-4 col-lg-4 mb-3">
                  <input
                    type="tel"
                    className="form-control"
                    id="phoneNumber"
                    placeholder="Enter phone number"
                    required
                    onChange={this.handleChange}
                  />
                </div>
                <label
                  htmlFor="email"
                  className="col-sm-3 col-md-2 col-lg-2 control-label"
                >
                  Email:
                </label>
                <div className="col-sm-9 col-md-4 col-lg-4">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter email"
                    required
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="subject"
                  className="col-sm-3 col-md-2 col-lg-2 control-label mb-3"
                >
                  Subject:
                </label>
                <div className="col-sm-9 col-md-4 col-lg-4 mb-3">
                  <select
                    type="text"
                    id="subject"
                    className="form-control"
                    onChange={this.handleChange}
                  >
                    <option />
                    <option>Contact</option>
                    <option>Referral Program</option>
                    <option>Careers</option>
                    <option>Other</option>
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="message"
                  className="col-sm-3 col-md-2 col-lg-2 control-label mb-3"
                >
                  Message:
                </label>
                <div className="col-sm-9 col-md-10 col-lg-10 y-50 mb-3">
                  <textarea
                    rows="6"
                    type="text"
                    className="form-control"
                    id="message"
                    placeholder="Enter message"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="y-100" />
            <div className="form-group row cen-mid">
              <input
                className="btn btn-md btn-primary"
                type="submit"
                value="Submit"
              />
            </div>
          </div>
        </form>
        <div className="y-100" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log(state);
  return {
    contactUsError: state.contact.contactUsError,
    contactUsSuccess: state.contact.contactUsSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    contactUs: props => dispatch(contactUs(props))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ContactForm);
