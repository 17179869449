const initState = {
  paymentUpdateSuccess: null,
  paymentUpdateError: null
};

const paymentReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_PAYMENT":
      console.log("Updated payment.");
      return {
        ...state,
        paymentUpdateSuccess: "Updated payment.",
        paymentUpdateError: null
      };

    case "UPDATE_PAYMENT_ERROR":
      console.log("Update payment error.");
      return {
        ...state,
        paymentUpdateSuccess: null,
        paymentUpdateError: action.err.message
      };

    default:
      return state;
  }
};

export default paymentReducer;
