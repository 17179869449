import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteUser } from "../../actions/profileActions";
import { Redirect } from "react-router-dom";

class DeleteAccount extends Component {
  handleDelete = e => {
    this.props.deleteUser(this.state);
  };

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to="/home" />;

    return (
      <div className="container-fluid">
        <div className="y-100 cen-mid">
          <h4 className="">Delete Account</h4>
        </div>
        <div>
          <p className="text-center text-muted d-none d-md-block">
            If you delete your account, you will no longer be able to login
            using
            <br />
            your email and password and will have to create a new account to
            gain
            <br />
            access to the user portal.
          </p>
          <p className="text-center text-muted d-md-none">
            If you delete your account, you will no longer be able to login
            using your email and password and will have to create a new account
            to gain access to the user portal.
          </p>
        </div>
        <br />
        <div className="cen-mid">
          <button
            className="btn btn-md btn-danger m-2"
            onClick={e => {
              if (window.confirm("Do you want to delete your account?"))
                this.handleDelete();
            }}
          >
            Delete
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteUser: props => dispatch(deleteUser(props))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteAccount);
