import React, { Component } from "react";
import { connect } from "react-redux";
import { refSignUp } from "../../actions/contactActions";

class Referral extends Component {
  state = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    businessName: "",
    source: "",
    benefit: "",
    referralCode: "",
  };

  makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    this.setState({ referralCode: result });
    console.log(this.state);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    if (this.state.referralCode === "") {
      this.makeid(8);
    }
    console.log(this.state);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.refSignUp(this.state);
  };

  render() {
    const { refSignUpError, refSignUpSuccess } = this.props;
    console.log(this.props);

    return (
      <div className="container-fluid">
        <div className="y-50" />
        <div className="row d-flex flex-column mx-auto">
          <div className="col-stretch cen-mid m-4">
            <h4 className="text-primary">Referral Program</h4>
          </div>
          <div className="col-stretch cen-mid p-2">
            <p className="font-italic">
              Want to <strong className="text-danger">MAKE $1,000</strong> for
              hardly any effort?
            </p>
          </div>
          <div className="col-stretch cen-mid p-2">
            <p className="font-italic">
              Know any small businesses' in need of a bookkeeper?
            </p>
          </div>
          <div className="col-stretch cen-mid p-2">
            <p className="">
              Refer them to us below and when they sign up you will receive a{" "}
              <strong className="text-danger">CASH BONUS</strong> equal to one
              month of the plan they sign up for.
            </p>
          </div>
          <div className="col-stretch cen-mid p-2">
            <p className="">
              <strong className="text-danger">UNLIMITED</strong> numbers of
              referrals allowed.
            </p>
          </div>
          <div className="col-stretch cen-mid p-2">
            <p className="">
              Money will be in your account within{" "}
              <strong className="text-danger">30 DAYS!</strong>
            </p>
          </div>
          <div className="col-stretch cen-mid p-2">
            <p className="">
              Call us on{" "}
              <a
                className="text-primary font-weight-bold"
                href="tel:+61290300315"
              >
                02 9030 0315
              </a>{" "}
              to discuss the program with one of our team members!
            </p>
          </div>

          <div className="col-stretch cen-mid mt-4">
            <small className="font-weight-bold">
              Terms and Conditions of Referral Program
            </small>
          </div>
          <div className="col-stretch cen-mid">
            <p className="font-italic f-07">
              Cash bonus will sent via direct debit in $AUD to nominated
              Australian Bank Account. Bonus will be paid upon receipt of second
              months' plan payment and successful posting of Google review.
              Bonus is only valid if business referred signs up for a minimum
              term of 3 months and completes all three monthly payments.
            </p>
          </div>
        </div>

        <div className="y-100" />
        <form className="form-horizontal m-4" onSubmit={this.handleSubmit}>
          <div className="p-4 cen-mid">
            <h5 className="">Apply for Referral Program</h5>
          </div>
          <div className="cen-mid">
            {refSignUpError ? (
              <div className="text-danger pb-4">
                <p>{refSignUpError}</p>
              </div>
            ) : null}
          </div>
          <div className="pb-4 cen-mid">
            {refSignUpSuccess ? (
              <div className="text-primary">
                <p>{refSignUpSuccess}</p>
              </div>
            ) : (
              <p>Enter your details below to be emailed a referral code.</p>
            )}
          </div>
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="text"
            >
              First name:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="text"
                id="firstName"
                className="form-control"
                placeholder="Enter first name"
                autoFocus
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="text"
            >
              Last name:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="text"
                id="lastName"
                className="form-control"
                placeholder="Enter last name"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="tel"
            >
              Phone number:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="tel"
                id="phoneNumber"
                className="form-control"
                placeholder="Enter phone number"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="email"
            >
              Email:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="email"
                id="email"
                className="form-control"
                placeholder="Enter email"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="text"
            >
              Business Name:
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="text"
                id="businessName"
                className="form-control"
                placeholder="Enter business name"
                aria-describedby="businessNameEx"
                onChange={this.handleChange}
              />
              <small id="businessNameEx" className="form-text text-muted">
                *Only applicable to existing clients.
              </small>
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="text"
            >
              Which benefit would you like?
            </label>
            <div
              className="col-sm-8 col-md-6 col-lg-4"
              onChange={this.handleChange}
            >
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="benefit"
                  id="benefit"
                  value="cash"
                />
                <label className="form-check-label" htmlFor="benefitCash">
                  Cash Benefit
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="benefit"
                  id="benefit"
                  value="credit"
                />
                <label className="form-check-label" htmlFor="benefitCredit">
                  Account Credit
                </label>
              </div>
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label
              className="control-label col-sm-4 col-md-4 col-lg-4 text-sm-left text-md-right text-lg-right"
              htmlFor="text"
            >
              Where did you hear about our referral program?
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="text"
                id="source"
                className="form-control"
                placeholder="Source"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div className="form-group row cen-mid">
            <input
              className="btn btn-md btn-primary"
              type="submit"
              value="Apply"
            />
          </div>
        </form>
        <div className="y-100" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    refSignUpSuccess: state.contact.refSignUpSuccess,
    refSignUpError: state.contact.refSignUpError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    refSignUp: (props) => dispatch(refSignUp(props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Referral);
