export const updateCard = props => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // const firebase = getFirebase();
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;
    const tokenId = props.token;
    firestore
      .collection("users")
      .doc(userId)
      .get()
      .then(resp => {
        firestore
          .collection("stripeTokens")
          .doc(tokenId)
          .set({
            customerId: resp.data().stripeId,
            tokenId: tokenId,
            userId: userId,
            dateCreated: new Date(),
            action: "update source"
          });
      })
      .then(() => {
        dispatch({ type: "TOKEN_ADD" });
      })
      .catch(err => {
        dispatch({ type: "TOKEN_ADD_ERROR", err });
      });
  };
};
