import React, { Component } from "react";
//import moment from "moment";
import { connect } from "react-redux";
import {
  editPlan,
  addPlan,
  deletePlan,
  cleanPlan
} from "../../actions/viewActions";
//import { Redirect } from "react-router-dom";
import { compose } from "redux";

class ViewPlans extends Component {
  state = {
    id: this.props.plan.id,
    planName: this.props.plan.planName,
    planPrice: this.props.plan.planPrice,
    planFeatures: this.props.plan.planFeatures,
    extras: this.props.plan.extras,
    size: this.props.plan.size
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
    console.log(this.state);
  };

  handleFeatures = (index, event) => {
    var planFeatures = this.props.plan.planFeatures;
    planFeatures[index] = event.target.value;
    console.log(this.state);
  };

  handleExtras = (index, event) => {
    var extras = this.props.plan.extras;
    extras[index] = event.target.value;
    this.setState({ extras: extras });
    console.log(this.state);
  };

  handleEdit = e => {
    e.preventDefault();
    console.log(this.state);
    this.props.editPlan(this.state);
  };

  handleAdd = e => {
    e.preventDefault();
    console.log(this.state);
    this.props.addPlan(this.state);
  };

  handleDelete = e => {
    e.preventDefault();
    console.log(this.state);
    this.props.deletePlan(this.state);
  };

  handleClean = e => {
    e.preventDefault();
    var extras = this.state.extras;
    for (let i = 0; i < extras.length; i++) {
      if (extras[i] === "") {
        extras.splice(i);
      }
      this.setState({ extras: extras });
    }
    var planFeatures = this.state.planFeatures;
    for (let i = 0; i < planFeatures.length; i++) {
      if (planFeatures[i] === "") {
        planFeatures.splice(i);
      }
      this.setState({ planFeatures: planFeatures });
    }
    console.log(this.state);
    this.props.cleanPlan(this.state);
  };

  addFeature = e => {
    e.preventDefault();
    var planFeatures = this.state.planFeatures;
    planFeatures.splice(planFeatures.length + 1, 0, " ");
    this.props.cleanPlan(this.state);
  };

  addExtras = e => {
    e.preventDefault();
    var extras = this.state.extras;
    extras.splice(extras.length + 1, 0, " ");
    this.setState({ extras: extras });
    this.props.cleanPlan(this.state);
  };

  deleteFeature = e => {
    e.preventDefault();
    var planFeatures = this.state.planFeatures;
    planFeatures.pop();
    this.setState({ planFeatures: planFeatures });
    this.props.cleanPlan(this.state);
  };

  deleteExtras = e => {
    e.preventDefault();
    var extras = this.state.extras;
    extras.pop();
    this.setState({ extras: extras });
    this.props.cleanPlan(this.state);
  };

  render() {
    const { plan } = this.props;
    //const { auth, plan } = this.props;
    //if (!auth.uid) return <Redirect to="/signin" />;
    console.log(this.state);

    return (
      <form
        className="form-horizontal mx-2"
        onSubmit={this.handleSubmit}
        to="/view"
      >
        <table className="table table-responsive-lg table-borderless m-0 p-0">
          <tbody>
            <tr>
              <td className="p-1">
                <select
                  type="number"
                  id="size"
                  className="form-control form-control-sm"
                  defaultValue={plan.size}
                  onChange={this.handleChange}
                >
                  <option />
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                </select>
              </td>
              <td className="p-1">
                <input
                  type="text"
                  id="planName"
                  className="form-control form-control-sm"
                  defaultValue={plan.planName}
                  onChange={this.handleChange}
                />
              </td>
              <td className="p-1">
                <input
                  type="number"
                  step="any"
                  id="planPrice"
                  className="form-control form-control-sm"
                  defaultValue={plan.planPrice}
                  onChange={this.handleChange}
                />
              </td>
              <td className="p-1">
                {plan.planFeatures &&
                  plan.planFeatures.map((features, index) => {
                    return (
                      <textarea
                        type="text"
                        step="any"
                        id="planFeatures"
                        className="form-control form-control-sm"
                        defaultValue={features}
                        onChange={this.handleFeatures.bind(this, index)}
                        key={index}
                      />
                    );
                  })}
              </td>
              <td className="p-1">
                {plan.extras &&
                  plan.extras.map((extra, index) => {
                    return (
                      <textarea
                        type="text"
                        step="any"
                        id="extras"
                        className="form-control form-control-sm"
                        defaultValue={extra}
                        onChange={this.handleExtras.bind(this, index)}
                        key={index}
                      />
                    );
                  })}
              </td>
              <td className="p-1">
                <button
                  className="btn btn-sm btn-success form-control-sm"
                  onClick={this.handleClean.bind(this)}
                >
                  <i className="fas fa-check" />
                </button>
                <br />
                <br />
                <button
                  className="btn btn-sm btn-danger form-control-sm"
                  onClick={this.handleDelete.bind(this)}
                >
                  <i className="far fa-trash-alt" />
                </button>
              </td>
            </tr>
            <tr>
              <td className="p-0" />
              <td className="p-0" />
              <td className="p-0" />
              <td className="p-0 text-center">
                <button
                  className="btn btn-sm text-success"
                  onClick={this.addFeature.bind(this)}
                >
                  <i className="fas fa-plus" />
                </button>
                {"  "}
                <button
                  className="btn btn-sm text-danger"
                  onClick={this.deleteFeature.bind(this)}
                >
                  <i className="fas fa-minus" />
                </button>
              </td>
              <td className="p-0 text-center">
                <button
                  className="btn btn-sm text-success"
                  onClick={this.addExtras.bind(this)}
                >
                  <i className="fas fa-plus" />
                </button>
                {"  "}
                <button
                  className="btn btn-sm text-danger"
                  onClick={this.deleteExtras.bind(this)}
                >
                  <i className="fas fa-minus" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  }
}

/*
  <button
                    className="btn btn-sm btn-primary form-control-sm"
                    onClick={this.handleEdit.bind(this)}
                  >
                    <i className="fas fa-edit" />{" "}
                  </button>
                  <br />
                  <br />
                <button
                  className="btn btn-sm btn-secondary form-control-sm"
                  onClick={this.handleClean.bind(this)}
                >
                  <i className="fas fa-broom" />
                </button>
*/

const mapStateToProps = state => {
  console.log(state);
  return {
    auth: state.firebase.auth,
    plans: state.firestore.ordered.plans
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editPlan: props => dispatch(editPlan(props)),
    addPlan: props => dispatch(addPlan(props)),
    deletePlan: props => dispatch(deletePlan(props)),
    cleanPlan: props => dispatch(cleanPlan(props))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ViewPlans);
