export const editPlan = props => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("plans")
      .doc(props.id)
      .update({
        ...props,
        lastUpdated: new Date()
      })
      .then(() => {
        dispatch({ type: "EDIT_PLAN" });
      })
      .catch(err => {
        dispatch({ type: "EDIT_PLAN_ERROR", err });
      });
  };
};

export const addPlan = props => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("plans")
      .add({
        ...props,
        lastUpdated: new Date()
      })
      .then(resp =>
        firestore
          .collection("plans")
          .doc(resp.id)
          .update({
            id: resp.id
          })
      )
      .then(() => {
        dispatch({ type: "ADDD_PLAN" });
      })
      .catch(err => {
        dispatch({ type: "ADD_PLAN_ERROR", err });
      });
  };
};

export const deletePlan = props => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("plans")
      .doc(props.id)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE_PLAN" });
      })
      .catch(err => {
        dispatch({ type: "DELETE_PLAN_ERROR", err });
      });
  };
};

export const cleanPlan = props => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("plans")
      .doc(props.id)
      .set({
        ...props,
        lastUpdated: new Date()
      })
      .then(() => {
        dispatch({ type: "DELETE_PLAN" });
      })
      .catch(err => {
        dispatch({ type: "DELETE_PLAN_ERROR", err });
      });
  };
};

export const editFeature = props => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("features")
      .doc(props.id)
      .update({
        ...props,
        lastUpdated: new Date()
      })
      .then(() => {
        dispatch({ type: "EDIT_FEATURE" });
      })
      .catch(err => {
        dispatch({ type: "EDIT_FEATURE_ERROR", err });
      });
  };
};

export const addFeature = props => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("features")
      .add({
        ...props,
        lastUpdated: new Date()
      })
      .then(resp =>
        firestore
          .collection("features")
          .doc(resp.id)
          .update({
            id: resp.id
          })
      )
      .then(() => {
        dispatch({ type: "ADD_FEATURE" });
      })
      .catch(err => {
        dispatch({ type: "ADD_FEATURE_ERROR", err });
      });
  };
};

export const deleteFeature = props => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("features")
      .doc(props.id)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE_FEATURE" });
      })
      .catch(err => {
        dispatch({ type: "DELETE_FEATURE_ERROR", err });
      });
  };
};

export const cleanFeature = props => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("features")
      .doc(props.id)
      .set({
        plan: props.plan,
        size: props.size,
        name: props.name,
        description: props.description,
        lastUpdated: new Date()
      })
      .then(() => {
        dispatch({ type: "CLEAN_FEATURE" });
      })
      .catch(err => {
        dispatch({ type: "CLEAN_FEATURE_ERROR", err });
      });
  };
};

export const editTicket = props => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("tickets")
      .doc(props.id)
      .update({
        ...props,
        lastUpdated: new Date()
      })
      .then(() => {
        dispatch({ type: "EDIT_TICKET" });
      })
      .catch(err => {
        dispatch({ type: "EDIT_TICKET_ERROR", err });
      });
  };
};
