import React from "react";

const SignedOutLinks = () => {
  return (
    <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-white border-bottom shadow navbar-height">
      <a className="navbar-brand m-0 ml-3" href="/home">
        Bookkeeping Stop
      </a>
      <button
        className="navbar-toggler mr-3"
        type="button"
        data-toggle="collapse"
        data-target="#navbarToggler"
        aria-controls="navbarToggler"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>

      <div className="collapse navbar-collapse p-0" id="navbarToggler">
        <ul className="navbar-nav mr-auto ml-2">
          <li className="nav-item mr-4">
            <a className="nav-link" href="/about">
              About
            </a>
          </li>
          <li className="nav-item mr-4">
            <a className="nav-link" href="/services">
              Services
            </a>
          </li>
          <li className="nav-item mr-4">
            <a className="nav-link" href="/pricing">
              Pricing
            </a>
          </li>
          <li className="nav-item mr-4">
            <a className="nav-link" href="/contact">
              Contact
            </a>
          </li>
        </ul>
        <div className="d-flex">
          <ul className="navbar-nav">
            <li className="nav-item mr-3">
              <a className="btn btn-outline-primary mr-1 mb-2" href="/referral">
                Referral Program
              </a>
            </li>
            <li className="nav-item mr-3">
              <a className="btn btn-primary" href="tel:+61290300315">
                <i className="fas fa-phone-volume mr-1 mb-1" /> 02 9030 0315
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/signin">
                <i className="fas fa-sign-in-alt mr-1 mb-1" /> Sign In
              </a>
            </li>
            <li className="nav-item mr-4">
              <a className="nav-link" href="/signup">
                <i className="fas fa-user-plus mr-1 mb-1" /> Sign Up
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

/*
<li className="nav-item">
              <a className="nav-link" href="/signup">
                <i className="fas fa-user-plus mr-1 mb-1" /> Sign Up
              </a>
            </li>

            <li className="nav-item">
            <a className="btn btn-sm btn-outline-danger
            " href="/portal">
              Portal Demo
            </a>
          </li>
*/

export default SignedOutLinks;
