export const draftLedger = props => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;
    firestore
      .collection("ledgerDraft")
      .doc(userId)
      .update({
        ...props,
        id: userId
      })
      .then(() => {
        dispatch({ type: "UPLOAD_LEDGER" });
      })
      .catch(err => {
        dispatch({ type: "UPLOAD_LEDGER_ERROR", err });
      });
  };
};
