import React, { Component } from "react";
import ServicesSm from "./ServicesSm";
import ServicesMd from "./ServicesMd";
import ServicesLg from "./ServicesLg";

class Services extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
        <ServicesSm />
        <div className="y-50" />
        <ServicesMd />
        <div className="y-50" />
        <ServicesLg />
        <div className="y-100 d-none d-md-block" />
        <div className="y-50 d-md-none" />
      </div>
    );
  }
}

export default Services;
