import React, { Component } from "react";
import TicketSummary from "./TicketSummary";
import TicketCreate from "./TicketCreate";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";

class TicketList extends Component {
  render() {
    const { auth, tickets } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div className="container-fluid">
        <div className="y-50" />
        <TicketCreate />
        <div className="y-50" />
        <div className="y-100 cen-mid">
          <h3 className="font-weight-normal">Outstanding Tickets</h3>
        </div>
        <div className="container">
          <div className="d-flex flex-wrap justify-content-center">
            {tickets &&
              tickets.map(ticket => {
                return <TicketSummary ticket={ticket} />;
              })}
          </div>
        </div>
        <div className="y-100" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    tickets: state.firestore.ordered.tickets
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: "tickets",
      orderBy: ["createdAt", "desc"]
    }
  ])
)(TicketList);
