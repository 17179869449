import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";

// Initialize Firebase
var config = {
  apiKey: "AIzaSyAx1KVS38O-MU7xD5VJohqU1qJyv7Zt9so",
  authDomain: "bkstop-a40d9.firebaseapp.com",
  databaseURL: "https://bkstop-a40d9.firebaseio.com",
  projectId: "bkstop-a40d9",
  storageBucket: "bkstop-a40d9.appspot.com",
  messagingSenderId: "262929851262"
};
firebase.initializeApp(config);
firebase.firestore();

export default firebase;
