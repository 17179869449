import React, { Component } from "react";
import { updateTicket } from "../../actions/ticketActions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class TicketUpdate extends Component {
  state = {
    id: this.props.id,
    title: this.props.ticket.title,
    content: this.props.ticket.content
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.updateTicket(this.state);
  };

  render() {
    const { ticket, auth } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div className="container text-center">
        <div className="y-50" />
        <main role="main">
          <div className="flex-column align-items-center p-4 bg-white">
            {/* FIX - redirect form to tickets when submitted */}
            <form
              onSubmit={this.handleSubmit}
              className="form-signin col-6 mx-auto"
              to="/support"
            >
              <div className="form-label-group">
                <input
                  type="text"
                  id="title"
                  className="form-control"
                  defaultValue={ticket.title}
                  required
                  autoFocus
                  onChange={this.handleChange}
                />
              </div>
              <br />
              <div className="form-group-lg">
                <textarea
                  id="content"
                  defaultValue={ticket.content}
                  className="form-control"
                  rows="6"
                  required
                  onChange={this.handleChange}
                />
              </div>
              <br />
              <input
                className="btn btn-lg btn-primary btn-md"
                type="submit"
                value="Submit"
              />
              <br />
            </form>
          </div>
        </main>
        <div className="y-50" />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const tickets = state.firestore.data.tickets;
  const ticket = tickets ? tickets[id] : null;
  return {
    id: id,
    ticket: ticket,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTicket: props => dispatch(updateTicket(props))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketUpdate);
